import { FaBuilding, FaUserTie, FaComments } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

export interface ChoiceType {
  icon: string | IconType | React.ReactChild;
  id?: number;
  title: string;
  weight: number;
  imageURL?: string;
  isLast?: boolean;
  link?: string;
  successor?: number;
  activeCampaignField?: {
    fieldTitle: string;
    fieldValue: string;
  };
  description?: string;
  subHeadline?: string | React.ReactChild;
  buttonClasses?: string;
  buttonText?: string;
}

export interface QuestionConfig {
  id: number;
  step?: number;
  question: string;
  type: 'radio' | 'input';
  choices: Array<ChoiceType>;
  activeCampaignField?: {
    fieldTitle: string;
    fieldValue: string;
  };
  condition?: {
    previousQuestionId: number;
    requiredAnswerWeight: number;
  };
  imageURL?: string;
}

interface FeatureCard {
  title: string;
  description: string;
  thumbnail: string;
}

export interface QuestionConfigV2 {
  id: number;
  step: number;
  question?: string;
  placeholder?: string;
  defaultSuccessor?: number;
  subtext?: string;
  skipHistory?: boolean;
  continuationAccess?: boolean;
  type: 'radio' | 'input' | 'success' | 'message' | 'guidelines';
  precursor?: number;
  choices: Array<ChoiceType>;
  activeCampaignField?: {
    fieldTitle: string;
    fieldValue: string;
  };
  condition?: {
    previousQuestionId: number;
    requiredAnswerWeight: number;
  };
  guidelines?: {
    question?: string;
    description?: string;
    title?: string;
    textItems?: string[];
  };
  featuresDetails?: {
    tagline?: string;
    title?: string;
    featureList: FeatureCard[];
  };
  isLast?: boolean;
}

export const newQuestions: Array<QuestionConfigV2> = [
  {
    id: 1,
    step: 1,
    precursor: 0,
    choices: [
      {
        icon: 'users-group',
        title: 'Als Dienstleister (für andere)',
        weight: 1,
        successor: 2,
        activeCampaignField: {
          fieldTitle: 'Typ',
          fieldValue: 'Agentur'
        }
      },
      {
        icon: 'user-1',
        title: 'Als Unternehmen (für uns)',
        weight: 2,
        successor: 3,
        activeCampaignField: {
          fieldTitle: 'Typ',
          fieldValue: 'Unternehmen'
        }
      }
    ],
    question: 'Wie willst du rekrutieren?',
    type: 'radio'
  },
  {
    id: 2,
    step: 2,
    precursor: 1,
    question: 'Wie viele Menschen arbeiten in deinem Dienstleistungsunternehmen?',
    type: 'radio',
    choices: [
      {
        icon: 'user-1',
        title: '1',
        weight: 1,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '1'
        }
      },
      {
        icon: 'users-2',
        title: '2-5',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '2-5'
        }
      },
      {
        icon: 'users-group',
        title: '5-15',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '5-15'
        }
      },
      {
        icon: 'building',
        title: '15+',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '> 15'
        }
      }
    ]
  },
  {
    id: 3,
    step: 2,
    precursor: 1,
    question: 'Wie viele Menschen arbeiten in deinem Unternehmen?',
    type: 'radio',
    choices: [
      {
        icon: 'user-1',
        title: '1-10',
        weight: 1,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '1-10'
        }
      },
      {
        icon: 'users-2',
        title: '11-50',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '11-50'
        }
      },
      {
        icon: 'users-group',
        title: '51-200',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '51-200'
        }
      },
      {
        icon: 'building',
        title: '200+',
        weight: 2,
        successor: 4,
        activeCampaignField: {
          fieldTitle: 'Anzahl der Mitarbeiter',
          fieldValue: '> 200'
        }
      }
    ]
  },
  {
    id: 4,
    step: 3,
    precursor: 3,
    question:
      'Hast du schon mal mit einem Recruiting-Funnel Werbeanzeigen auf Social-Media geschaltet?',
    type: 'radio',
    choices: [
      {
        icon: 'tick',
        title: 'Ja',
        weight: 1,
        successor: 5,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Grundlagen'
        }
      },
      {
        icon: 'cross',
        title: 'Nein',
        weight: 2,
        successor: 12,
        activeCampaignField: {
          fieldTitle: 'Fähigkeitslevel',
          fieldValue: 'Keine'
        }
      }
    ]
  },
  {
    id: 5,
    step: 4,
    precursor: 4,
    question: 'Mit welcher Software hast du bisher deine Recruiting-Funnels aufgesetzt?',
    placeholder: 'z.B. ich habe Software X zusammen mit Software Y verwendet',
    defaultSuccessor: 6,
    type: 'input',
    choices: [
      {
        icon: 'tick',
        title: 'Ja',
        weight: 1,
        successor: 7
      }
    ],
    featuresDetails: {
      tagline: '6/100+ einzigartigen Funktionen',
      title: 'Kleiner Einblick in verschiedene Funktionen, die MEETOVO so wirkungsvoll machen',
      featureList: [
        {
          title: 'Bewerbermanagement',
          description:
            'Nutze unser integriertes, intuitives System oder verknüpfe dein eigenes – so behältst du alle Bewerbungen im Blick.',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/application-management.png'
        },
        {
          title: 'Ad Builder',
          description:
            'Erstelle deine Anzeigen direkt in MEETOVO – ganz ohne Photoshop, Canva oder Copywriting-Kenntnisse',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/ad-builder.png'
        },
        {
          title: 'E-Mail & SMS',
          description:
            'Versende E-Mails oder SMS automatisch zur richtigen Zeit – mit nur einem Klick, ganz ohne zusätzliche Tools.',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/email-and-sms.png'
        },
        {
          title: 'Kalendar',
          description:
            'Vereinbare Termine in Echtzeit – inklusive direkter Google-Kalender-Synchronisation. Calendly & Co. kannst du dir sparen.',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/calendar.png'
        },
        {
          title: 'Jobportale (Indeed, Google Jobs, 10+ weitere)',
          description:
            'Indexiere deine Anzeigentexte mit einem Klick auf verschiedenen Jobplattformen wie Indeed, LinkedIn Jobs, Google Jobs, Glassdoor uvm.',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/google-jobs.png'
        },
        {
          title: 'Videos & Sprachnachrichten',
          description:
            'Neben Videos im Funnel kannst du auch personliche Sprachnachrichten einbinden',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/video-voice-message.png'
        }
      ]
    }
  },
  {
    id: 6,
    step: 5,
    precursor: 5,
    question: 'Wie bist du eigentlich auf MEETOVO gestoßen?',
    placeholder: 'z.B. Facebook Ads, Empfehlung, Linkedin, Instagram etc.',
    defaultSuccessor: 11,
    type: 'input',
    choices: [
      {
        icon: 'tick',
        title: 'Ja',
        weight: 1,
        successor: 11
      }
    ]
  },
  {
    id: 11,
    step: 0,
    precursor: 13,
    isLast: true,
    defaultSuccessor: 13,
    question: 'Super! Du kannst 14 kostenfreie Tage freischalten.',
    subtext:
      'Du kannst die Software jetzt testen, ohne Zahlungsdaten angeben zu müssen. Es entsteht kein Abo.',
    type: 'success',
    choices: [],
    featuresDetails: {
      tagline: 'Du bist nie allein',
      title: 'Erstklassiger Support. Jederzeit. Kostenfrei.',
      featureList: [
        {
          title: 'Live-Chat mit Experten',
          description:
            'Schreibe unten rechts mit einem ausgebildeten Recruiting-Experten per Live-Chat. Dich erwartet ein echter Mensch, der ⌀ in 9 Minuten antwortet',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/Thumbnail.png'
        },
        {
          title: 'Recruiting Academy',
          description:
            'Verstehe jedes noch so kleine Detail. Die Recruiting Academy (7 Std. Videomaterial) ist die perfekte Ausbildungsstelle für jeden, der Social Recruiting erlernen möchte',

          thumbnail: '/dashboard/onboarding/feature-thumbnails/Thumbnail-2.png'
        },
        {
          title: 'Hilfecenter',
          description:
            'Neben dem Live-Chat und der ausführlichen Recruiting Academy kannst du dir autodidaktisch alles selbst anlesen, sollten noch offene Fragen bestehen',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/Thumbnail-3.png'
        }
      ]
    }
  },
  {
    id: 12,
    step: 0,
    precursor: 3,
    defaultSuccessor: 7,
    type: 'guidelines',
    skipHistory: true,
    choices: [],
    guidelines: {
      question: 'Du willst das erste Mal, Mitarbeiter mit Social Recruiting gewinnen?',
      textItems: [
        'Social Media Rekrutierung funktioniert in fast jeder Branche. Während sich andere auf Jobportale und herkömmliche Methode verlassen, wirst du gezielt Millionen von wechselbereiten Kandidaten erreichen.',
        'MEETOVO ist das einzige Tool, das du brauchst, um schon morgen deine erste Social Recruiting Kampagnen veröffentlicht zu haben, ohne jegliche Vorerfahrung.',
        'In den nächsten 14 Tagen wirst du alles aufgesetzt bekommen, sodass auch du Bewerbungen für etwa 10 - 150€ pro Stück erhalten wirst.'
      ]
    },
    featuresDetails: {
      featureList: [
        {
          title: '1. Anzeigen erstellen',
          description:
            'Erstelle Werbetexte- und Bilder direkt in MEETOVO. Mit wenigen Klicks veröffentlicht.',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/social-media.png'
        },
        {
          title: '2. Recruiting-Funnel aufsetzen',
          description: 'Präsentiere das Unternehmen und qualifiziere spielerisch die Kandidaten',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/recruiting-funnel.png'
        },
        {
          title: '3. Bewerbungen verwalten',
          description:
            'Erhalte Bewerbungen nach ⌀ 48 Stunden und verwalte die Bewerbungen direkt im Dashboard',
          thumbnail: '/dashboard/onboarding/feature-thumbnails/manage-application.png'
        }
      ]
    }
  },
  {
    id: 7,
    step: 5,
    precursor: 12,
    question: 'Wie bist du eigentlich auf MEETOVO gestoßen?',
    placeholder: 'z.B. Facebook Ads, Empfehlung, Linkedin, Instagram etc.',
    defaultSuccessor: 11,
    type: 'input',
    choices: [
      {
        icon: 'tick',
        title: 'Ja',
        weight: 1,
        successor: 11
      }
    ]
  }
];

export const questions: Array<QuestionConfig> = [
  {
    id: 1,
    step: 1,
    question: 'Welche Option beschreibt dich am besten?',
    type: 'radio',
    choices: [
      {
        icon: FaBuilding,
        title: 'Agentur',
        weight: 1,
        activeCampaignField: {
          fieldTitle: 'Option',
          fieldValue: 'Agentur'
        }
      },
      {
        icon: FaUserTie,
        title: 'Freelancer',
        weight: 2,
        activeCampaignField: {
          fieldTitle: 'Option',
          fieldValue: 'Freelancer'
        }
      },
      {
        icon: FaComments,
        title: 'Berater',
        weight: 3,
        activeCampaignField: {
          fieldTitle: 'Option',
          fieldValue: 'Berater'
        }
      }
    ]
  }
];
