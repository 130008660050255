import { useNode, useEditor } from '@craftjs/core';
import React, { useState } from 'react';
import { BsFillGearFill, BsFillTrashFill } from 'react-icons/bs';
import { IoIosCopy } from 'react-icons/io';

import { useAppDispatch } from '../../../../redux/hooks';
import { editDraggingElement } from '../../../redux/builderSlice';
import { getClonedTree } from '../../../helper/craftJs';
import { AppendNewContainer } from '../elements/common/AppendNewTemplate';
import { CRAFT_ELEMENTS } from '../../../config/craftElements';
import { Tooltip } from 'antd';
import { tooltipText } from '../../../helper/tooltipText';
import HideAction from '../../../renderNode/components/HideAction';
import getAdGenerationInput from '../../../graphql/getAdGenerationInput';
import Templates from '../../Templates/container';
import TextAiAction from '../../../renderNode/components/TextAiAction';
import { SETTINGS_VALUE, useElementSubSettings } from '../../../helper/richTextHelpers';
import { useDownloadAd } from '../../../components/ui/DownloadAd';
import { FaCloudDownloadAlt, FaRedo } from 'react-icons/fa';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';

interface Props {
  background?: string;
  paddingTop?: string;
  paddingBottom?: string;
  marginTop?: string;
  marginBottom?: string;
  children?: any;
  style?: any;
  className?: string;
  canDrag?: boolean;
  addNewContainerBtnReq?: boolean;
  ParentTag?: any;
  ParentTagProps?: any;
  onlySettingsReq?: boolean;
  settingsNotReq?: boolean;
  copyNotReq?: boolean;
  deleteNotReq?: boolean;
  textAiReq?: boolean;
  innerRef?: any;
  actionIcon?: React.ReactNode;
  label?: string;
}

export const ActionsController = ({
  children,
  className = '',
  canDrag = false,
  ParentTag,
  ParentTagProps,
  innerRef,
  onlySettingsReq,
  copyNotReq,
  deleteNotReq,
  textAiReq,
  settingsNotReq,
  actionIcon,
  addNewContainerBtnReq,
  label,
  ...props
}: Props) => {
  const [visible, toggleVisible] = useState(false);
  const [adGenerationData, setAdGenerationData] = useState({});
  const { goToElementSubSettings } = useElementSubSettings();
  const { handleDownloadAd, loading } = useDownloadAd();
  const dispatch = useAppDispatch();

  const {
    connectors: { connect, drag },
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id
    };
  });

  const { actions, query, enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled
  }));

  const { selected } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected: any;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }

    return {
      selected
    };
  });

  const { currentNode } = useNode(node => ({ currentNode: node }));

  const isHovered = currentNode.events.hovered;

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    actions.delete(selected.id);
  };

  const copyCurrentSection = () => {
    const theNode = query.node(nodeId).get();
    const parentNode = query.node(theNode.data.parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(nodeId);

    const oldTree = query.node(nodeId).toNodeTree();
    const tree = getClonedTree(oldTree, query);
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);
    return;
  };
  const handleReGenerateAd = async () => {
    toggleVisible(true);
    const adId = query.getSerializedNodes()[selected?.id].props.id;
    if (adId) {
      const response = await getAdGenerationInput(adId);
      const data = response.data.getAdGenerationInput;
      data[`promptMessage${data.type}`] = data.promptMessage;
      setAdGenerationData({ ...data, regenerateAd: true });
    } else {
      setAdGenerationData({ nodeId: selected?.id, regenerateAd: true });
    }
  };

  const handleDownload = async () => {
    handleDownloadAd([selected?.id]);
  };

  const Tag = ParentTag || 'div';

  const handleSwitchSettings = async (e: any) => {
    if(!selected?.props) return;
    if (currentNode.data.displayName === CRAFT_ELEMENTS.RICH_TEXT) {
      goToElementSubSettings(SETTINGS_VALUE.GENERAL);
    }
    if (currentNode.data.displayName === CRAFT_ELEMENTS.PLAIN_TEXT) {
      goToElementSubSettings(SETTINGS_VALUE.AI);
    }
  };

  return (
    <Tag
      id={nodeId}
      {...props}
      ref={(ref: any) => {
        connect(drag(ref));
        if (innerRef) innerRef.current = ref;
      }}
      className={`actions-controller__wrapper ${className} ${
        selected?.id === nodeId ? 'active-actions-controller__wrapper' : ''
      }`}
      onDragStart={(e: any) => {
        var el = document.createElement('div');
        e.dataTransfer.setDragImage(el, 0, 0);
        e.stopPropagation();
        if (canDrag) {
          e.preventDefault();
          return;
        }
        if (
          [CRAFT_ELEMENTS.CONTAINER, 'ElementAppender', 'DividerComponent'].includes(selected.name)
        )
          dispatch(editDraggingElement({ name: 'ActionsController' }));
      }}
      onDragEnd={(e: any) => {
        dispatch(editDraggingElement({ name: null }));
      }}
      onClick={handleSwitchSettings}
      {...ParentTagProps}
    >
      {label && isHovered && <div className="actions-controller__label">{label}</div>}
      <div className="action-icons action-position-top">
        <ul>
          {actionIcon && actionIcon}
          {currentNode.data.props.canHide && <HideAction />}
          {textAiReq && <TextAiAction />}
          {!settingsNotReq && (
            <Tooltip title={tooltipText.settings}>
              <li className={`${onlySettingsReq && 'setting-icon'}`}>
                <BsFillGearFill />
              </li>
            </Tooltip>
          )}
          {!onlySettingsReq && !copyNotReq && (
            <Tooltip title={tooltipText.copy}>
              <li onClick={copyCurrentSection}>
                <IoIosCopy />
              </li>
            </Tooltip>
          )}
          {currentNode.data.displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER && (
            <Tooltip title={tooltipText.downloadAd}>
              <li onClick={handleDownload}>
                {loading ? (
                  <DefaultSmallLoader makeItCenter={true} fontSize={18} loading={true} />
                ) : (
                  <FaCloudDownloadAlt fontSize={18} />
                )}
              </li>
            </Tooltip>
          )}
          {currentNode.data.displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER &&
            currentNode.data.props.isAiAd && (
              <Tooltip title={tooltipText.reGenerateAd}>
                <li
                  className="mr-2 cursor-pointer action-icon text-ai"
                  onClick={handleReGenerateAd}
                >
                  <FaRedo />
                </li>
              </Tooltip>
            )}

          {!onlySettingsReq && !deleteNotReq && (
            <Tooltip title={tooltipText.delete}>
              <li className="trash-icon" onClick={handleDeleteClick}>
                <BsFillTrashFill />
              </li>
            </Tooltip>
          )}
        </ul>
      </div>

      {currentNode.data.props.canHide?.hideSwich ? (
        <div className="hidden-container py-3"></div>
      ) : (
        children
      )}
      <Templates
        visible={visible}
        toggleVisible={toggleVisible}
        adGenerationData={adGenerationData as AdGeneration}
      />
      {addNewContainerBtnReq && <AppendNewContainer />}
    </Tag>
  );
};
