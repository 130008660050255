import React from 'react';
import { Tooltip, Typography, Row, Col } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { BookingInterface } from '../../container/Events';
import { calculateAverageRating, getColorForRating } from '../../helper/bookingRating';

type Props = {
  event: BookingInterface;
  children?: React.ReactNode;
  disable?: boolean;
};

const BookingItemRating = ({ event, children, disable = false }: Props) => {
  const totalAverageRating = calculateAverageRating(event.bookingRatings);
  const ratingAverage = parseFloat(
    (
      (totalAverageRating?.competancy +
        totalAverageRating?.communicationSkills +
        totalAverageRating?.teamCompatibility) /
      3
    ).toFixed(1)
  );

  return (
    <div>
      {ratingAverage > 0 && (
        <div style={{ marginLeft: 'auto' }}>
          <Tooltip
            title={!disable &&
              <div className="rating-tooltip">
                <Typography.Title level={4} className="mb-4 title">
                  Bewertung
                </Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col xs={18}>
                    <Typography.Text>Fachliche Kompetenz</Typography.Text>
                  </Col>
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    <Typography.Text strong style={{ marginRight: '5px' }}>
                      {totalAverageRating?.competancy}
                    </Typography.Text>
                    <StarFilled
                      style={{ color: getColorForRating(totalAverageRating?.competancy) }}
                    />
                  </Col>

                  <Col xs={18}>
                    <Typography.Text>Teamkompatibilität</Typography.Text>
                  </Col>
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    <Typography.Text strong style={{ marginRight: '5px' }}>
                      {totalAverageRating?.teamCompatibility}
                    </Typography.Text>
                    <StarFilled
                      style={{ color: getColorForRating(totalAverageRating?.teamCompatibility) }}
                    />
                  </Col>

                  <Col xs={18}>
                    <Typography.Text>Kommunikationsfähigkeit</Typography.Text>
                  </Col>
                  <Col xs={6} style={{ textAlign: 'right' }}>
                    <Typography.Text strong style={{ marginRight: '5px' }}>
                      {totalAverageRating?.communicationSkills}
                    </Typography.Text>
                    <StarFilled
                      style={{ color: getColorForRating(totalAverageRating?.communicationSkills) }}
                    />
                  </Col>

                </Row>
              </div>
            }
          >
            <div className="cursor-pointer rating-box">
              {children || (
                <>
                  <StarFilled style={{ color: getColorForRating(ratingAverage), marginRight: '3px' }} />
                  <Typography.Text>{ratingAverage}</Typography.Text>
                </>
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default BookingItemRating;
