import ListItem from '@tiptap/extension-list-item';
import { Extension } from '@tiptap/core'
import { FONT_MARK } from '../../../Builder/components/elements/RichTextComponent/utils/fontSize';

export const editorClasses = {
  root: 'nml__editor__root',
  toolbar: {
    hr: 'nml__editor__toolbar__hr',
    root: 'nml__editor__toolbar__root',
    bold: 'nml__editor__toolbar__bold',
    code: 'nml__editor__toolbar__code',
    undo: 'nml__editor__toolbar__undo',
    redo: 'nml__editor__toolbar__redo',
    link: 'nml__editor__toolbar__link',
    clear: 'nml__editor__toolbar__clear',
    italic: 'nml__editor__toolbar__italic',
    strike: 'nml__editor__toolbar__strike',
    hardbreak: 'nml__editor__toolbar__hardbreak',
    unsetlink: 'nml__editor__toolbar__unsetlink',
    alignLeft: 'nml__editor__toolbar__align__left',
    blockquote: 'nml__editor__toolbar__blockquote',
    bulletList: 'nml__editor__toolbar__bullet__list',
    alignRight: 'nml__editor__toolbar__align__right',
    orderedList: 'nml__editor__toolbar__ordered__list',
    alignCenter: 'nml__editor__toolbar__align__center',
    alignJustify: 'nml__editor__toolbar__align__justify'
  },
  content: {
    hr: 'nml__editor__content__hr',
    root: 'nml__editor__content__root',
    link: 'nml__editor__content__link',
    image: 'nml__editor__content__image',
    heading: 'nml__editor__content__heading',
    listItem: 'nml__editor__content__listItem',
    blockquote: 'nml__editor__content__blockquote',
    langSelect: 'nml__editor__content__lang__select',
    placeholder: 'nml__editor__content__placeholder',
    bulletList: 'nml__editor__content__bullet__list',
    orderedList: 'nml__editor__content__ordered__list',
    underline: 'nml__editor__content__underlined'
  }
};


export const CustomEnterBehavior = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
          const { selection } = editor.state;
          const { $from } = selection;
          
          const isAtEnd = $from.pos === $from.end();
          if (!isAtEnd) {
            return false;
          }

          const marks = $from.marks();
          const { textAlign } = $from.parent.attrs;
          const fontSizeMark = marks.find(mark => mark.type.name === FONT_MARK);
          if (fontSizeMark) {
            const fontSize = fontSizeMark.attrs[FONT_MARK];
            let headingLevel = 0;
            if (fontSize === '1em') {
              headingLevel = 0;
            } else if (fontSize === '1.5em') {
              headingLevel = 3;
            } else if (fontSize === '2.5em') {
              headingLevel = 2;
            } else if (fontSize === '3.5em') {
              headingLevel = 1;
            }

            if (headingLevel > 0) {
              let chain = editor.chain()
                .insertContentAt($from.pos, `<h${headingLevel} style="text-align: ${textAlign};"></h${headingLevel}>`)
              marks.forEach(mark => {
                chain = chain.setMark(mark.type.name, mark.attrs)
              })
              chain.run();
              return true;
            }
          }
          return false;
      },
    }
  },
})

export const listItemColor = ListItem.extend({
  addAttributes() {
    const attrs = this.parent?.() || {};
    return {
      ...attrs,
      color: {
        default: null,
        parseHTML: element => element.style.color || null,
        renderHTML: attributes => {
          if (!attributes.color) return {};
          return { style: `color: ${attributes.color};` };
        }
      },
      flag: {
        default: true
      }
    };
  },
});

export const updateListItemColor = (editor: any) => {
  const currentColorData = editor.getAttributes('textStyle')?.color || '#777';
  const flagdata = editor.getAttributes('listItem')?.flag;

  if (flagdata && currentColorData && editor.isActive('listItem')) {
    editor.commands.updateAttributes('listItem', {
      color: currentColorData,
      flag: false
    });
    editor.commands.setMark('textStyle', { color: currentColorData });
  }
};
