import React, { useEffect, useRef, useState } from 'react';
import { useEditor, useNode, Element } from '@craftjs/core';
import { CRAFT_ELEMENTS } from '../../../../../config/craftElements';
import { getUniqueId } from '../../../../../helper/craftJs';
import ImageComponent from '../../ImageComponent';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { handleSelectCraftElement } from '../../../../../helper/sharedFunctions';
import { Tooltip } from 'antd';
import { tooltipText } from '../../../../../helper/tooltipText';
import { AiFillSave } from 'react-icons/ai';
import CreateNewTemplate from '../components/CreateNewAdMedia';
interface Props {
  children?: React.ReactNode;
  hidden?: boolean;
}
const MediaContentComponent = (props: Props) => {
  const { children, hidden = false } = props;
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { actions, query } = useEditor();

  useEffect(() => {
    if (currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(<Element id={getUniqueId()} is={ImageComponent}></Element>)
        .toNodeTree();
      actions.addNodeTree(nodeTree, currentNode.id);
    }
  }, []);

  return (
    <>
      <ActionsController
        className="media-content-wrapper"
        innerRef={containerRef}
        onlySettingsReq={true}
        canDrag={true}
      >
        {children}
      </ActionsController>
    </>
  );
};

const MediaContentComponentProps = {
  canHide: {
    hideSwich: false
  }
};

export const MediaContentWrapperSettings = () => {
  const { actions, query } = useEditor();
  const {
    actions: { setProp },
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id
    };
  });
  handleSelectCraftElement({
    actions,
    nodeId,
    query,
    openChildSettingsOnParentSettings: true,
    openParentSettingsOnChildSettings: false
  });
  return null;
};

MediaContentComponent.craft = {
  name: CRAFT_ELEMENTS.MEDIA_WRAPPER,
  props: MediaContentComponentProps,
  related: {
    settings: MediaContentWrapperSettings
  },
  rules: {
    canMoveOut: () => {
      return false;
    }
  }
};

export default MediaContentComponent;
