import { IconType } from '../components/elements/SingleChoiceComponent/components/Choice';

export const defaultQuestion = '<h2 style="text-align: center"><span style="font-size: 2.5em">Was ist dir bei einem neuen Arbeitsplatz wichtig?<span></h2>';

export interface DefaultChoice {
  content: string;
  type: IconType;
  value: string;
}

export const defaultChoices = [
  { content: 'Sicherer & stabiler Arbeitsplatz', type: IconType.icon, value: 'FaShieldAlt' },
  { content: 'Freie Zeiteinteilung', type: IconType.icon, value: 'FiClock' },
  { content: 'Weiterbildung', type: IconType.icon, value: 'FaBook' }
];
