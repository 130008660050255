export const defaultQuestion = '<h2 style="text-align: center"><span style="font-size: 2.5em">Wie viele Jahre Erfahrung hast du?</span></h2>';

export const defaultProps = {
  initialValue: 3,
  min: 0,
  max: 20,
  unit: 'Jahre',
  onlySettingsReq: true,
  canDrag: true
};

export const stepsByMax = [
  { max: 100, step: 1 },
  { max: 500, step: 10 },
  { max: 1000, step: 25 },
  { max: 10000, step: 100 },
  { max: 25000, step: 250 },
  { max: 100000, step: 1000 },
  { max: 250000, step: 2500 },
  { max: 500000, step: 5000 },
  { max: 1000000, step: 10000 }
];
