import { ELEMENTS_WITH_ELEMENT_APPENDER, SizeInterface } from '../config/builderUi';
import lz from 'lzutf8';
import {
  builderCommonElementsTypes,
  BuilderPageDataType,
  EmailTemplateType,
  EmailType,
  FUNNEL_THEME_KEYS,
  ConnectedFonts,
  FunnelThemeType
} from '../interfaces/builderSliceTypes';
import { useAllPages, useBuilderSelectedPageId } from '../redux/builderSlice';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import { CRAFT_ELEMENTS as AD_BUILDER_CRAFT_ELEMENTS } from '../AdBuilder/config/craftElements';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import { Delete } from 'craftjs-utils-meetovo';
import { FontAvailableSizes } from '../interfaces/FontSizeInterface';
import { NodeData, Nodes, NodeTree } from '@craftjs/core';
import { reduceColorOpacity } from './sharedFunctions';
import { textTypesEnum, mediaTypeEnum } from '../AdBuilder/interfaces/TextTypeInterface';
import { base64toBlob } from '../../GeneralComponents/helper/cropImage';
import * as htmlToImage from 'html-to-image';
import * as Sentry from '@sentry/browser';
import { CalendarSettings } from '../graphql/setCalendarSettings';

import {
  AGENCY_OS_THEME_KEYS,
  AgencyOSBuilderPageDataType,
  AgencyOSThemeType
} from '../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { userInteractingComponents } from './elementTemplate';
import { CRAFT_IDENTIFIER } from '../AdJobBuilder/config/craftElements';
import {
  AVAILABLE_FONT_TYPE,
  CONNECTED_FONT_TYPE
} from '../components/sharedUI/BuilderSettingsFont';
import { contactPageType } from './defaultValues';
import { htmlToPlainText } from '../components/elements/RichTextComponent/helpers/textManipulators';
interface AppendCommonElementsAndEncodePage {
  page: BuilderPageDataType;
  header: any;
  footer: any;
  selectedPageId: number | null;
  currentCraftStateNotSyncedWithRedux: string;
}

export interface CraftElementBaseProps {
  showPlusIcon?: boolean;
  copyNotReq?: boolean;
  canDrag?: boolean;
  onlySettingsReq?: boolean;
  deleteNotReq?: boolean;
  canHide?: {
    hideSwich: boolean;
  };
  actionIcon?: React.ReactNode;
  elementTitle?: string;
  openChildSettingsOnParentSettings?: boolean;
  openParentSettingsOnChildSettings?: boolean;
  activeActionControllerClassNotRequired?: boolean;
  showParentSettingsOnChildHover?: boolean;
  identifier?: CRAFT_IDENTIFIER;
  customRules?: {
    saveable?: boolean;
    noSettings?: boolean;
  };
  currentBuilderIdentifier?: string;
}

const parentContainerComponents = [CRAFT_ELEMENTS.ELEMENT_APPENDER, CRAFT_ELEMENTS.CAROUSEL_SLIDE];

export const containerSelectionOnColumn = (
  element: HTMLInputElement | HTMLDivElement,
  nodeId: string,
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  selectNode: (arg0: any) => void
) => {
  if (element.getAttribute('data-node-info') === CRAFT_ELEMENTS.COLUMN) {
    const columnGridNodeId = query.node(nodeId).get().data.parent;
    const containerNodeId = query.node(columnGridNodeId).get().data.parent;
    selectNode(containerNodeId);
  }
};

export const parentSelectionOnChildClick = (
  nodeId: string,
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  selectNode: (arg0: any) => void
) => {
  const parent = query.getNodes()[nodeId].data.parent;
  selectNode(parent);
};

export const childSelectionOnParentClick = (
  nodeId: string,
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  selectNode: (arg0: any) => void
) => {
  const child = query.node(nodeId).get().data.nodes[0];
  selectNode(child);
};
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}

// generateId :: Integer -> String
export function getRandomId(len?: number) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export const getRandomInteger = () => Math.floor(Math.random() * 9999999999999);

export function copyNodesWithChildNodes(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  actions: any,
  nodeId: any,
  parentId: any
) {
  const freshNode = query.node(nodeId).get();

  // const parentId = freshNode.data.parent;
  const index =
    query
      .node(parentId)
      .descendants(false)
      .indexOf(nodeId) + 1;

  const uniqueId = getUniqueId();
  // Create a new valid Node object from the fresh Node
  const node = query.parseFreshNode(freshNode).toNode((n: any) => {
    n.id = uniqueId;
    n.data.nodes = [];
    return n;
  });
  actions.add(node, parentId, index);

  if (freshNode.data.nodes.length > 0) {
    let childNodeIds = JSON.parse(JSON.stringify(freshNode.data.nodes));
    childNodeIds.reverse().forEach((element: string) => {
      copyNodesWithChildNodes(query, actions, element, uniqueId);
    });
  }
  return uniqueId;
}

export function addTemplate(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  actions: any,
  serializeNode: any,
  startNodeId: string,
  parentId: string,
  index?: number | null
) {
  const uniqueId = getUniqueId();
  const node = query.parseSerializedNode(serializeNode[startNodeId]).toNode((n: any) => {
    n.id = uniqueId;
    n.data.nodes = [];
    return n;
  });

  index === 0 || index ? actions.add(node, parentId, index + 1) : actions.add(node, parentId);
  if (serializeNode[startNodeId].nodes.length > 0) {
    let childNodeIds = JSON.parse(JSON.stringify(serializeNode[startNodeId].nodes));
    childNodeIds.forEach((element: string) => {
      addTemplate(query, actions, serializeNode, element, uniqueId);
    });
  }
  return uniqueId;
}

export function copyNodeWithUpdatedProps(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  actions: any,
  nodeId: any,
  parentId: any,
  extraProps: any
) {
  const freshNode = query.node(nodeId).get();
  const index =
    query
      .node(parentId)
      .descendants(false)
      .indexOf(nodeId) + 1;

  const uniqueId = getUniqueId();
  // Create a new valid Node object from the fresh Node
  const node = query.parseFreshNode(freshNode).toNode((n: any) => {
    n.id = uniqueId;
    n.data.nodes = [];
    extraProps && (n.data.props = { ...n.data.props, ...extraProps });
    return n;
  });
  actions.add(node, parentId, index);
  actions.delete(nodeId);

  if (freshNode.data.nodes.length > 0) {
    let childNodeIds = JSON.parse(JSON.stringify(freshNode.data.nodes));
    childNodeIds.reverse().forEach((element: string) => {
      copyNodesWithChildNodes(query, actions, element, uniqueId);
    });
  }
}

export const updateNodeStyle = (
  node: string,
  key: string,
  value: string,
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) => {
  query
    .node(node)
    .ancestors()
    .forEach((nodeId: string) => {
      if (query.node(nodeId).get().dom) {
        if (query.node(nodeId).get().dom?.style) {
          query.node(nodeId).get().dom.style[key] = value;
        }
      }
    });
};

export function useCraftSerializedState(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
) {
  const nodes = query.getSerializedNodes();
  Object.keys(nodes).forEach(key => {
    if (nodes[key].displayName == CRAFT_ELEMENTS.RICH_TEXT) {
      if (nodes[key].props.editorState) {
        delete nodes[key].props.editorState;
      }
      if (nodes[key].props.rawState) {
        delete nodes[key].props.rawState;
      }
      delete nodes[key].props.editorInstance;
    } else if (nodes[key].displayName == AD_BUILDER_CRAFT_ELEMENTS.PLAIN_TEXT) {
      if (nodes[key].props.rawState) {
        delete nodes[key].props.rawState;
      }
      if (nodes[key].props.editorState) {
        delete nodes[key].props.editorState;
      }
    }
  });
  return nodes;
}

export function useCraftStringifiedState(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
) {
  const nodes = useCraftSerializedState(query);
  return lz.encodeBase64(lz.compress(JSON.stringify(nodes)));
}

export function useHeaderFromCraftState(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) {
  const nodes = query.getSerializedNodes();
  const Nkey = Object.keys(nodes).find((key: any) => nodes[key].displayName == CRAFT_ELEMENTS.LOGO);
  // @ts-ignore
  nodes[Nkey].id = Nkey;
  // @ts-ignore
  return nodes[Nkey];
}
export function useFooterFromCraftState(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) {
  const nodes = query.getSerializedNodes();

  const Nkey = Object.keys(nodes).find(
    (key: any) => nodes[key].displayName == CRAFT_ELEMENTS.FOOTER
  );
  // @ts-ignore
  nodes[Nkey].id = Nkey;
  // @ts-ignore
  return nodes[Nkey];
}

export const getMainContainerNode = (
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) => {
  const containerId = query
    .node('ROOT')
    .get()
    .data.nodes.find((key: string) => {
      if (query.node(key).get().data.displayName === CRAFT_ELEMENTS.MAIN_CONTAINER) {
        return key;
      }
    });
  if (containerId) {
    return query.node(containerId).get();
  } else return {};
};

export const getRootAndMainContainerNodes = (
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) => {
  const nodes = query.getSerializedNodes();
  const Nkey = Object.keys(nodes).find(
    (key: any) => nodes[key].displayName == CRAFT_ELEMENTS.MAIN_CONTAINER
  );
  return [...query.node('ROOT').descendants(false)[0], ...query.node(Nkey).descendants(false)];
};

export const appendCommonElementsToCraftState = (
  commonComponents: builderCommonElementsTypes,
  state: string
): string => {
  const { header, footer } = commonComponents;
  let nodes: { [key: string]: any } = craftToJSON(state);
  if (typeof nodes === 'string') {
    nodes = JSON.parse(nodes);
  }

  let Fkey = '',
    Lkey = '';

  nodes['ROOT'].nodes.forEach((key: string) => {
    if (nodes[key].displayName === CRAFT_ELEMENTS.LOGO) {
      Lkey = key;
    } else if (nodes[key].displayName === CRAFT_ELEMENTS.FOOTER) {
      Fkey = key;
    }
  });

  const hData = craftToJSON(header);
  const fData = craftToJSON(footer);

  if (fData && Fkey) {
    nodes[Fkey].props = { ...fData.props };
    nodes['ROOT'].nodes = nodes['ROOT'].nodes.filter((single: string) => {
      return single !== Fkey;
    });
    nodes['ROOT'].nodes.push(Fkey);
    nodes[Fkey].parent = 'ROOT';
  }
  if (hData && Lkey) {
    nodes[Lkey].props = { ...hData.props };
    nodes[Lkey].parent = 'ROOT';
  }

  return JSONtoCraft(nodes);
};

export const getKeyValueObjectByKeyValueArrayOfObjects = (
  sizes: any[]
): { [key: number]: string } =>
  sizes.reduce((acc, { value, key }) => {
    acc[key] = value;
    return acc;
  }, {});

export const getKeyByValue = (value: string, sizes: any[], keyToMap?: string): SizeInterface =>
  sizes.find(single => value === single[keyToMap || 'value']) || sizes[0];

export const getValueByKey = (key: number, sizes: any[]): SizeInterface =>
  sizes.find(({ key: thisKey }) => key === thisKey) || sizes[0];

export const applyThemeOnSerializedNode = (json: any) => {
  const backgroundKeys: string[] = [];
  const colorKeys: string[] = [];
  const accentBackgroundKeys: string[] = [];
  const accentContrastTextColorKeys: string[] = [];
  const contactFormButtonAccentBackgroundKeys: string[] = [];
  const contactFormButtonTextColorKeys: string[] = [];

  Object.keys(json).forEach((key: any) => {
    if (json[key].displayName == CRAFT_ELEMENTS.ICON || json[key].displayName == 'IconComponent')
      colorKeys.push(key);

    if (json[key].displayName == CRAFT_ELEMENTS.BUTTON) {
      accentBackgroundKeys.push(key);
      accentContrastTextColorKeys.push(key);
    }

    if (
      json[key].displayName === CRAFT_ELEMENTS.MAIN_CONTAINER ||
      json[key].displayName === CRAFT_ELEMENTS.CONTAINER ||
      json[key].displayName === CRAFT_ELEMENTS.LOGO ||
      json[key].displayName === CRAFT_ELEMENTS.FOOTER ||
      json[key].displayName === CRAFT_ELEMENTS.DIVIDER ||
      json[key].displayName == CRAFT_ELEMENTS.ELEMENT_APPENDER
    )
      backgroundKeys.push(key);

    if (json[key].displayName === CRAFT_ELEMENTS.CONTACT_FORM) {
      contactFormButtonAccentBackgroundKeys.push(key);
      contactFormButtonTextColorKeys.push(key);
    }
  });

  return {
    backgroundKeys,
    colorKeys,
    accentBackgroundKeys,
    contactFormButtonAccentBackgroundKeys,
    accentContrastTextColorKeys,
    contactFormButtonTextColorKeys
  };
};

export function handleKeyBoardShortcut(e: any, canUndo: boolean, canRedo: boolean, actions: any) {
  return; // temporary disabled until draftjs conflict is solved
  if ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ' && canUndo && !e.shiftKey) {
    actions.history.undo();
  }
  if (
    (((e.ctrlKey || e.metaKey) && e.code === 'KeyY') ||
      ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ' && e.shiftKey)) &&
    canRedo
  ) {
    actions.history.redo();
  }
}

export const addDefaultPropsInSerialzedNode = (json: any) => {
  Object.keys(json).forEach((key: any) => {
    if (json[key].displayName == CRAFT_ELEMENTS.ELEMENT_APPENDER) {
      json[key].props.showSaveableModal = false;
      json[key].props.copyNotReq = true;
      json[key].props.customRules = { saveable: true };
    } else if (json[key].displayName === CRAFT_ELEMENTS.CONTAINER) {
      json[key].props.showSaveableModal = false;
      json[key].props.customRules = { saveable: true };
    }
  });
};

export const applyThemeOnTemplateSerializedNodes = (
  currentTheme: FunnelThemeType,
  json: any
): string => {
  const {
    backgroundKeys,
    colorKeys,
    accentBackgroundKeys,
    contactFormButtonAccentBackgroundKeys,
    contactFormButtonTextColorKeys,
    accentContrastTextColorKeys
  } = applyThemeOnSerializedNode(json);
  addDefaultPropsInSerialzedNode(json);

  backgroundKeys.forEach(key => {
    json[key].props.backgroundColor = currentTheme.backgroundColor;
  });

  accentBackgroundKeys.forEach(key => {
    json[key].props.backgroundColor = currentTheme.accentColor;
  });

  colorKeys.forEach(key => {
    json[key].props.color = currentTheme.accentColor;
  });

  contactFormButtonAccentBackgroundKeys.forEach(key => {
    json[key].props.buttonBackgroundColor = currentTheme.accentColor;
  });

  contactFormButtonTextColorKeys.forEach(key => {
    json[key].props.buttonTextColor = currentTheme.accentColorContrast;
  });

  accentContrastTextColorKeys.forEach(key => {
    json[key].props.color = currentTheme.accentColorContrast;
  });

  return json;
};

export const appendStyles = ({
  _document,
  id,
  styles
}: {
  _document: any;
  id: string;
  styles: string;
}): void => {
  _document.getElementById(id)?.remove();
  const styleElement = _document.createElement('style');
  styleElement.setAttribute('id', id);
  styleElement.setAttribute('type', 'text/css');
  const styleInner = _document.createTextNode(styles);
  styleElement.appendChild(styleInner);
  _document.body.appendChild(styleElement);
};

export const getConnectedFonts = (
  connectedFonts: ConnectedFonts[],
  fontType: CONNECTED_FONT_TYPE
) => {
  const font = connectedFonts?.find((item: ConnectedFonts) => item.type === fontType)
    ?.availableFont;
  return font;
};

export const appendStylesAccordingToColorTheme = (
  theme: FunnelThemeType | AgencyOSThemeType,
  isMobileView?: boolean,
  connectedFonts?: ConnectedFonts[]
): void => {
  const _document = document;

  const { fontFamily, fontLink, headlineColor, textColor, accentColor, cardTextColor } = theme;
  const textConnectedFonts =
    connectedFonts && getConnectedFonts(connectedFonts, CONNECTED_FONT_TYPE.TEXT);
  const headlineConnectedFonts =
    connectedFonts && getConnectedFonts(connectedFonts, CONNECTED_FONT_TYPE.HEADING);
  // @ts-ignore
  const backgroundColor = theme[FUNNEL_THEME_KEYS.BACKGROUND_COLOR]
    ? // @ts-ignore
    theme[FUNNEL_THEME_KEYS.BACKGROUND_COLOR]
    : // @ts-ignore
    theme[AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR];

  // @ts-ignore
  const accentColorContrast = theme[FUNNEL_THEME_KEYS.ACCENT_COLOR_CONTRAST]
    ? // @ts-ignore
    theme[FUNNEL_THEME_KEYS.ACCENT_COLOR_CONTRAST]
    : // @ts-ignore
    theme[AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR];
  const textFontFamily = textConnectedFonts?.fontFamily || fontFamily;
  const textFontLink = textConnectedFonts?.fontLink || fontLink;
  const headlineFontFamily = headlineConnectedFonts?.fontFamily || fontFamily;
  const headlineFontLink = headlineConnectedFonts?.fontLink || fontLink;

  const textFontImport =
    textConnectedFonts?.type === AVAILABLE_FONT_TYPE.GOOGLE_FONTS || !textConnectedFonts?.fontFamily
      ? `@import url('${textFontLink}');`
      : `@font-face { font-family: ${textFontFamily}; src: url(${textFontLink}); }`;
  const headlineFontImport =
    headlineConnectedFonts?.type === AVAILABLE_FONT_TYPE.GOOGLE_FONTS ||
      !textConnectedFonts?.fontFamily
      ? `@import url('${headlineFontLink}');`
      : `@font-face { font-family: ${headlineFontFamily}; src: url(${headlineFontLink}); }`;
  const accentColorWithoutHash = `${accentColorContrast?.replace('#', '%23')}`;

  const headlineStyles = `
  ${headlineFontImport}
  .preview-section .rich-text__wrapper span[style*='font-size: 1.5em'] *,
  .preview-section .rich-text__wrapper span[style*='font-size: 2.5em'] *,
  .preview-section .rich-text__wrapper span[style*='font-size: 3.5em'] * {
    font-family: ${headlineFontFamily}, sans-serif !important;
    color: ${headlineColor};
  }

  .ad-builder-preview .rich-text__wrapper span[style*='font-size: 1.5em'] *,
  .ad-builder-preview .rich-text__wrapper span[style*='font-size: 2.5em'] *,
  .ad-builder-preview .rich-text__wrapper span[style*='font-size: 3.5em'] * {
    font-family: ${headlineFontFamily}, sans-serif !important;
  }
  .preview-section .rich-text__wrapper span[style*='font-size: 1.5em'][style*='color'] > span[data-text='true'],
  .preview-section .rich-text__wrapper span[style*='font-size: 2.5em'][style*='color'] > span[data-text='true'],
  .preview-section .rich-text__wrapper span[style*='font-size: 3.5em'][style*='color'] > span[data-text='true'] {
    color: inherit;
  }
  .preview-section .rich-text__wrapper span[style*='font-size: 1.5em'],
  .preview-section .rich-text__wrapper span[style*='font-size: 2.5em'],
  .preview-section .rich-text__wrapper span[style*='font-size: 3.5em'] {
    font-family: ${headlineFontFamily}, sans-serif !important;
    color: ${headlineColor};
  }
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 1.5em'] *,
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 2.5em'] *,
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 3.5em'] * {
    font-family: ${headlineFontFamily}, sans-serif !important;
    color: ${cardTextColor};
  }
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 1.5em'],
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 2.5em'],
  .preview-section .rich-text__wrapper.faq-override span[style*='font-size: 3.5em'] {
    font-family: ${headlineFontFamily}, sans-serif !important;
    color: ${cardTextColor};
  }
  .preview-section .rich-text__wrapper span[style*='font-size: 1.5em'] > [style*='color: '] *,
  .preview-section .rich-text__wrapper span[style*='font-size: 2.5em'] > [style*='color: '] *,
  .preview-section .rich-text__wrapper span[style*='font-size: 3.5em'] > [style*='color: '] * {
    color: inherit;
  }

  `;

  const styles = `
            ${textFontImport}
            .media-content-wrapper * {
              font-family: ${textFontFamily}, sans-serif !important;
            }

            .builder-view * {
              font-family: ${textFontFamily}, sans-serif !important;
            }

            .preview-section .rich-text__wrapper .public-DraftStyleDefault-block > span {
              font-family: ${textFontFamily}, sans-serif !important;
              color: ${textColor};
            }

            .preview-section .rich-text__wrapper .rich-text__inner p, span[style*='font-size: 1em'] {
              font-weight: 400;
              font-family: ${textFontFamily}, sans-serif !important;
              color: ${textColor};
            }

             .preview-section .rich-text__wrapper.faq-override .rich-text__inner p {
              font-weight: 400;
              font-family: ${textFontFamily}, sans-serif !important;
              color: ${cardTextColor};
            }

            .preview-section .rich-text__wrapper.faq-override .public-DraftStyleDefault-block > span {
              font-family: ${textFontFamily}, sans-serif !important;
              color: ${cardTextColor};
            }

            .preview-section .carousel-caption .rich-text__wrapper .public-DraftStyleDefault-block > span {
              color: white;
            }

            .preview-section .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
              color:${textColor};
              height: 100% !important;
              display: flex !important;
              font-size: 1em !important;
              align-items: center !important;
            }

            .preview-section .voice-message-preview__image {
              border: 2px solid ${accentColor};
            }

            .preview-section .voice-message-preview__image .play-circle,
            .preview-section .voice-message-preview__image .play-circle:before,
            .preview-section .voice-message-preview__image .play-circle:after {
              background-color: ${accentColor};
            }

            .preview-section .voice-message-preview__image .play-circle * {
              color: ${accentColorContrast} !important;
            }

            .preview-section .calendar .day-selector .day-selector__days li.active .dom {
              background: ${accentColor} !important;
            }

            .preview-section .calendar .timeslot-wrapper .timeslot {
              border-color:${accentColor} !important;
              color:${accentColor} !important;
            }

            .preview-section .choice-inner-wrapper .form-check-input:checked {
              background-color: ${accentColor} !important;
              border-color: ${accentColor} !important;
            }
            .preview-section .choice-inner-wrapper._active{
              border: 2px solid ${accentColor} !important;
              box-shadow: 0 8px 10px -5px #${reduceColorOpacity(
    accentColor,
    0.2
  )}, 0 16px 24px 2px #${reduceColorOpacity(
    accentColor,
    0.3
  )}, 0 6px 30px 5px #${reduceColorOpacity(accentColor, 0.2)} !important;
            }

            .preview-section .rangeslider-horizontal .rangeslider__fill {
              background-color: ${accentColor} !important;
            }

            .preview-section .rangeslider-wrapper__value {
              color: ${textColor} !important;
            }

            .contact-form__wrapper a, .preview-section a, .preview-section .footer-wrapper a.footer-wrapper__link {
              color: ${textColor} !important;
            }

            .preview-section .footer-wrapper .footer-wrapper__branding a, .preview-section .footer-wrapper .footer-wrapper__branding {
              color: ${textColor} !important;
            }

            .preview-section .form-check-label {
              color: ${textColor} !important;
            }

            .preview-section .choice-wrapper .form-check-input:checked {
              border-color: ${accentColorContrast || backgroundColor} !important;
            }

            .preview-section .form-check-input:checked[type=checkbox] {
              background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27${accentColorWithoutHash}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e") !important;
            }

            .builder__content .preview-section {
              background: ${
                isMobileView || isMobileView === undefined ? '#f9f9f9' : backgroundColor
    } !important;
            }


        `;

  appendStyles({
    _document,
    id: 'brand-color-styles-v2',
    styles
  });
  appendStyles({
    _document,
    id: 'brand-color-styles-v3',
    styles: headlineStyles
  });
};

export const isCalendarExistInFunnel = () => {
  const pages = useAllPages();
  const page = pages.find(page => {
    const craftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));
    return Object.keys(craftState).find(
      key => craftState[key].displayName === CRAFT_ELEMENTS.CALENDAR
    );
  });
  return {
    ...page,
    ...(page && { calendarEnabled: true })
  };
};

export const isQueryFNAndLNEnabled = () => {
  const pages = useAllPages();
  const page = pages.find(page => {
    const craftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));
    return Object.keys(craftState).find(
      key =>
        craftState[key].displayName === CRAFT_ELEMENTS.CONTACT_FORM &&
        craftState[key].props.breakNameFieldIntoFNAndLN
    );
  });
  return {
    ...page,
    ...(page && { queryFNAndLNEnabled: true })
  };
};

export const isAnyProvidedNodeExist = (
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  selectNode: string,
  nodes: string[]
) => {
  let existingNode: any = nodes.find(
    node => node === query.getState()?.nodes?.[selectNode]?.data.name
  );
  if (existingNode) return existingNode;
  query.getState()?.nodes?.[selectNode]?.data?.nodes.find((nodeId: string) => {
    existingNode = nodes.find(node => node === query.node(nodeId).get().data.name);
    if (existingNode) return true;
  });
  if (existingNode) return existingNode;
  for (let c1 = 0; c1 < query.getState()?.nodes?.[selectNode]?.data?.nodes.length; c1++) {
    existingNode = isAnyProvidedNodeExist(
      query,
      query.getState()?.nodes?.[selectNode]?.data?.nodes[c1],
      nodes
    );
    if (existingNode) return existingNode;
  }
};

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const waitForCondtion = (condition: () => boolean) =>
  new Promise((resolve, reject) => {
    const timeout = 10000;
    const interval = 100;
    let iterations = 0;

    const thisInterval = setInterval(() => {
      if (condition()) {
        resolve(true);
        clearInterval(thisInterval);
      }

      if (iterations * interval >= timeout) reject('Timeout element not found');

      iterations++;
    }, interval);
  });

export const getUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomChars = Math.random()
    .toString(36)
    .substring(2, 5);
  return `${timestamp}-${randomChars}`;
};

export const getParentNodes = (
  nodeId: string,
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) => {
  const parentNodeId = query?.getState()?.nodes[nodeId]?.data?.parent;
  if (!parentNodeId) return [];
  const nodes = query?.getState()?.nodes?.[parentNodeId]?.data?.nodes;
  return nodes || [];
};

export const extractCommonElementsFromState = (state: string): builderCommonElementsTypes => {
  let FooterKey = '',
    HeaderKey = '';
  const PAGE_NODES = craftToJSON(state);

  PAGE_NODES['ROOT'].nodes.forEach((key: string) => {
    if (PAGE_NODES[key].displayName === CRAFT_ELEMENTS.LOGO) {
      HeaderKey = key;
    } else if (PAGE_NODES[key].displayName === CRAFT_ELEMENTS.FOOTER) {
      FooterKey = key;
    }
  });

  const commonElements = {
    // @ts-ignore
    header: lz.encodeBase64(lz.compress(JSON.stringify(PAGE_NODES[HeaderKey]))),
    // @ts-ignore
    footer: lz.encodeBase64(lz.compress(JSON.stringify(PAGE_NODES[FooterKey])))
  };

  return commonElements;
};

export function useStringifiedCommonElements(
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>
) {
  const headerState = useHeaderFromCraftState(query);
  const footerState = useFooterFromCraftState(query);

  return {
    header: lz.encodeBase64(lz.compress(JSON.stringify(headerState))),
    footer: lz.encodeBase64(lz.compress(JSON.stringify(footerState)))
  };
}
export const getCraftNodeProps = (serializedNode: any, elementName: string) => {
  const nodeIds: string[] = Object.keys(serializedNode).filter(
    key => serializedNode[key].displayName === elementName
  );
  return nodeIds.map(nodeId => serializedNode[nodeId].props);
};

export const isThisIsAParent = (query: any, nodeId: string) => {
  try {
    const parentNodeId = query.node(nodeId)?.get()?.data?.parent;
    if (parentNodeId)
      return parentContainerComponents.includes(query.node(parentNodeId).get()?.data?.displayName);
  } catch (error) {
    return false;
  }
};

export const addDefaultPropsInElement = (
  elementName: string,
  setProp: (selector: string | string[], cb: (props: any) => void) => void,
  nodeId: string
) => {
  switch (elementName) {
    case CRAFT_ELEMENTS.LOGO:
      setProp(nodeId, (state: any) => (state.onlySettingsReq = true));
      break;
    case CRAFT_ELEMENTS.FOOTER:
      setProp(nodeId, (state: any) => (state.onlySettingsReq = true));
      break;
    case CRAFT_ELEMENTS.ELEMENT_APPENDER:
      setProp(nodeId, (state: any) => (state.showSaveableModal = false));
      setProp(nodeId, (state: any) => (state.copyNotReq = true));
      setProp(nodeId, (state: any) => (state.customRules = { saveable: true }));
      break;
    case CRAFT_ELEMENTS.CONTAINER:
      setProp(nodeId, (state: any) => (state.showSaveableModal = false));
      setProp(nodeId, (state: any) => (state.customRules = { saveable: true }));
      break;
  }
};

export const addDefaultPropsInElementOnParentBase = (
  elementName: string,
  parentElementName: string,
  setProp: (selector: string | string[], cb: (props: any) => void) => void,
  parentNode: NodeData,
  nodeId: string
) => {
  switch (parentElementName) {
    case CRAFT_ELEMENTS.ELEMENT_APPENDER:
      switch (true) {
        case CRAFT_ELEMENTS.RICH_TEXT === elementName &&
          [
            ELEMENTS_WITH_ELEMENT_APPENDER.MULTIPLE_SELECTION,
            ELEMENTS_WITH_ELEMENT_APPENDER.SINGLE_SELECTION,
            ELEMENTS_WITH_ELEMENT_APPENDER.RANGE_SLIDER,
            ELEMENTS_WITH_ELEMENT_APPENDER.TEXT_REPLY
          ].includes(parentNode?.props?.elementTitle):
          setProp(nodeId, (state: any) => (state.required = true));
          break;
      }
      break;
  }
};

export const updateElementsPropsBeforeCloning = (tree: { rootNodeId: string; nodes: Nodes }) => {
  const treeWithUpdatedProps = { ...tree };
  Object.keys(treeWithUpdatedProps.nodes).forEach(nodeId => {
    const node = treeWithUpdatedProps.nodes[nodeId];
    const elementName = node.data.displayName;
    switch (elementName) {
      case CRAFT_ELEMENTS.CONTAINER:
      case CRAFT_ELEMENTS.ELEMENT_APPENDER:
        node.data.props.sectionId += ' Kopie';
        break;
    }
  });
  return treeWithUpdatedProps;
};

export const findIfElementExistsAlready = (
  builderPages: BuilderPageDataType[],
  element: string
): boolean => {
  return builderPages.some(page => {
    const parsedCraftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));

    const componentKeys = Object.keys(parsedCraftState);

    return componentKeys.some((key: string) => {
      const { displayName } = parsedCraftState[key];
      return displayName === element && page.type != contactPageType;
    });
  });
};

export const reWampFunnelPages = (builderPages: BuilderPageDataType[]): BuilderPageDataType[] => {
  return builderPages.map(page => {
    let parsedCraftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));

    const componentKeys = Object.keys(parsedCraftState);
    componentKeys.forEach((key: string) => {
      const { displayName, props } = parsedCraftState[key];
      const { optionLogic, actionLogic } = props || {};

      if (displayName === CRAFT_ELEMENTS.BUTTON && typeof actionLogic === 'number') {
        parsedCraftState[key].props.tempLogicIndex = getPageIndexFromId(
          builderPages,
          props.actionLogic
        );
      } else if (displayName === CRAFT_ELEMENTS.CHOICE && typeof optionLogic === 'number') {
        parsedCraftState[key].props.tempLogicIndex = getPageIndexFromId(
          builderPages,
          props.optionLogic
        );
      }
    });

    return { ...page, craftState: lz.encodeBase64(lz.compress(JSON.stringify(parsedCraftState))) };
  });
};

export const getPageIndexFromId = (pages: BuilderPageDataType[], id: any) => {
  return pages.findIndex(_ => _.id == id);
};

export const addRandomNodeIdsToUserInteractingComponentsOnCraftState = (craftState: string) => {
  const hashMap: any = {};
  const nodes = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));

  Object.keys(nodes).forEach(key => {
    if (
      nodes[key].displayName === CRAFT_ELEMENTS.SINGLE_CHOICE ||
      nodes[key].displayName === CRAFT_ELEMENTS.MULTIPLE_CHOICE
    ) {
      hashMap[key] = getUniqueId();
      nodes[key].nodes.forEach((childKey: any) => {
        hashMap[childKey] = getUniqueId();
      });
    } else if (nodes[key].displayName === CRAFT_ELEMENTS.LONG_ANSWER) {
      hashMap[key] = getUniqueId();
    } else if (nodes[key].displayName === CRAFT_ELEMENTS.RANGE_SLIDER) {
      hashMap[key] = getUniqueId();
    }
  });

  const data = replaceKeys(hashMap, JSON.stringify(nodes));
  const state = JSON.parse(data);

  return lz.encodeBase64(lz.compress(JSON.stringify(state)));
};

const replaceKeys = (map = {}, source = '') => {
  var expression = new RegExp(Object.keys(map).join('|'), 'g');

  return source.replace(expression, function(m) {
    // @ts-ignore
    return map[m] || '';
  });
};

export const changeNodesIds = (
  rootNode: any,
  nodes: Nodes,
  newNodes: Nodes,
  query?: any,
  newParentId?: string
) => {
  const newNodeId = getUniqueId();

  const childNodes = rootNode.data.nodes.map((childId: any) =>
    changeNodesIds(nodes[childId], nodes, newNodes, query, newNodeId)
  );
  const linkedNodes = Object.keys(rootNode.data.linkedNodes).reduce((accum, id) => {
    const randId = changeNodesIds(
      nodes[rootNode.data.linkedNodes[id]],
      nodes,
      newNodes,
      query,
      newNodeId
    );
    return {
      ...accum,
      [id]: randId
    };
  }, {});

  let tmpNode = {
    ...rootNode,
    id: newNodeId,
    data: {
      ...rootNode.data,
      parent: newParentId || rootNode.data.parent,
      nodes: childNodes,
      linkedNodes
    }
  };
  let freshnode;
  if (query) freshnode = query.parseFreshNode(tmpNode).toNode();

  newNodes[newNodeId] = freshnode || tmpNode;
  return newNodeId;
};

export const getClonedTree = (
  tree: NodeTree,
  query?: Delete<useInternalEditorReturnType['query'], 'deserialize'>
) => {
  const newNodes: Nodes = {};

  const rootNodeId = changeNodesIds(tree.nodes[tree.rootNodeId], tree.nodes, newNodes, query);
  return {
    rootNodeId,
    nodes: newNodes
  };
};

interface AdContentType {
  [mediaTypeEnum.MEDIA]?: Blob;
  [textTypesEnum.HEADLINE]: string;
  [textTypesEnum.TEASER]: string;
  [textTypesEnum.MAIN_TEXT]: string;
  [textTypesEnum.CTA]: string;
}

export const getAdContent = async (
  wrapperId: string,
  serializedState: { [key: string]: NodeData },
  query: any
): Promise<AdContentType> => {
  const tempData = {} as AdContentType;

  await Promise.all(
    serializedState[wrapperId].nodes.map(async key => {
      const props = serializedState[key].props;
      if (serializedState[key].displayName === CRAFT_ELEMENTS.MEDIA_WRAPPER) {
        const mediaContainer = query.node(key).get().dom;
        if (mediaContainer && !serializedState[key].props.canHide.hideSwich) {
          const imageLink = await htmlToImage.toJpeg(mediaContainer, {
            backgroundColor: '#F9F9F9',
            cacheBust: true
          });

          tempData.MEDIA = base64toBlob({ b64Data: imageLink });
        }
      } else if (serializedState[key].displayName === AD_BUILDER_CRAFT_ELEMENTS.FOOTER) {
        const textKey = serializedState[key].nodes[0];
        tempData.HEADLINE = serializedState[textKey].props.plainText ?? htmlToPlainText(serializedState[textKey].props.html);
      } else if (props.elementType === textTypesEnum.TEASER) {
        tempData.TEASER = props.plainText ?? htmlToPlainText(props.html);
      } else if (props.elementType === textTypesEnum.MAIN_TEXT) {
        tempData.MAIN_TEXT = props.plainText ?? htmlToPlainText(props.html);
      } else if (props.elementType === textTypesEnum.CTA) {
        tempData.CTA = props.plainText ?? htmlToPlainText(props.html);
      }
    })
  );

  return tempData;
};

export const splitTemplateIntoFiles = (data: AdContentType, fileName: string) => {
  const text = `

${data.TEASER}



${data.MAIN_TEXT}



${data.CTA}



${data.HEADLINE}


  `;

  const fileData = [{ name: `${fileName}_copy.txt`, data: text, type: 'text' }];
  if (data.MEDIA) {
    // @ts-ignore
    fileData.push({ name: `${fileName}_creative.jpg`, data: data.MEDIA, type: 'media' });
  }
  return fileData;
};

export const removeDuplicateNodeIdsFromCraftState = (craftState: string) => {
  const json: { [key: string]: NodeData } = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));
  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      let nodes = json[key].nodes;
      json[key].nodes = Array.from(new Set(nodes));
    }
  }
  return lz.encodeBase64(lz.compress(JSON.stringify(json)));
};

export const removeDuplicateNodeIdsFromAllPages = (pages: BuilderPageDataType[]) => {
  const pagesClone = JSON.parse(JSON.stringify(pages));
  const fixedPages = pagesClone.map((page: BuilderPageDataType) => ({
    ...page,
    craftState: removeDuplicateNodeIdsFromCraftState(page.craftState)
  }));
  return fixedPages;
};

export const doesAnyDuplicateNodeIdsExistInCraftState = (craftState: string): boolean => {
  const json: { [key: string]: NodeData } = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));
  let duplicatesExist = false;
  for (let key in json) {
    if (json.hasOwnProperty(key)) {
      let nodes = json[key].nodes;
      const uniqueNodes = new Set(nodes);
      if (uniqueNodes.size !== nodes?.length) {
        duplicatesExist = true;
        break;
      }
    }
  }
  return duplicatesExist;
};

export const reportToSentryIfThereAreAnyDuplicateNodeIdsInAllPages = (
  pages: BuilderPageDataType[]
) => {
  try {
    for (let page of pages) {
      const hasDuplicates = doesAnyDuplicateNodeIdsExistInCraftState(page.craftState);
      if (hasDuplicates) {
        Sentry.withScope(scope => {
          scope.setExtras({
            currentPageCraftState: page.craftState
          });
          Sentry.captureMessage('Duplicate Node Ids Exception', Sentry.Severity.Critical);
        });
        break;
      }
    }
  } catch (error) {
    console.log('Error:reportToSentryIfThereAreAnyDuplicateNodeIdsInAllPages', error);
  }
};

export const JSONtoCraft = (state: object) => {
  return lz.encodeBase64(lz.compress(JSON.stringify(state)));
};

export const craftToJSON = (state: string) => {
  const json = JSON.parse(lz.decompress(lz.decodeBase64(state)));
  if (typeof json === 'string') return JSON.parse(json);
  else return json;
};

const appendCommonElementsAndEncodePage = ({
  page,
  header,
  footer,
  selectedPageId,
  currentCraftStateNotSyncedWithRedux
}: AppendCommonElementsAndEncodePage) => {
  let thisCraftState =
    selectedPageId === page.id ? currentCraftStateNotSyncedWithRedux : page.craftState;
  const craftState = appendCommonElementsToCraftState(
    {
      header: header,
      footer: footer
    },
    thisCraftState
  );

  return {
    ...page,
    craftState: craftState
  };
};

export const extractCalendarSettings = (
  page: BuilderPageDataType
): CalendarSettings | undefined => {
  let json = lz.decompress(lz.decodeBase64(page.craftState));
  const craftState = JSON.parse(json);

  let calendarSettings: any = null;

  const key = Object.keys(craftState).find(
    key => craftState[key].displayName === CRAFT_ELEMENTS.CALENDAR
  );

  if (key) calendarSettings = JSON.parse(JSON.stringify(craftState[key]));

  return calendarSettings?.props;
};

export const removeEmptyContainersFromPage = (
  page: BuilderPageDataType | AgencyOSBuilderPageDataType
) => {
  let json = lz.decompress(lz.decodeBase64(page.craftState));
  const pageState = JSON.parse(json);

  const containerIds = Object.keys(pageState).filter(
    (key: any) =>
      pageState[key].displayName == CRAFT_ELEMENTS.CONTAINER && pageState[key].nodes.length === 0
  );

  if (containerIds.length) {
    containerIds.forEach(key => {
      const parent = pageState[key].parent;
      pageState[parent].nodes = pageState[parent].nodes.filter((nodeId: string) => nodeId !== key);
      // @ts-ignore
      delete pageState[key];
    });
  }

  return {
    ...page,
    craftState: JSONtoCraft(pageState)
  };
};

interface GetEncodedBuilderPagesAndCalendarSettings {
  builderPages: BuilderPageDataType[];
  selectedPageId: number | null;
  query?: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
  oldTheme: FunnelThemeType;
  newTheme: FunnelThemeType;
  isEditingColorTheme: boolean;
}

interface GetEncodedAgencyDashboardBuilderPages {
  agencyOSBuilderPages: AgencyOSBuilderPageDataType[];
  selectedAgencyBuilderPageId: number | null;
  query?: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
}

export const getEncodedBuilderPagesCalendarSettingsAndCommonElements = ({
  builderPages,
  selectedPageId,
  query,
  oldTheme,
  newTheme,
  isEditingColorTheme
}: GetEncodedBuilderPagesAndCalendarSettings) => {
  query = (query || window.craftJsPageBuilderQuery) as Delete<
    useInternalEditorReturnType<any>['query'],
    'deserialize'
  >;

  const shouldApplyFunnelTheme = oldTheme.id === newTheme.id;

  let currentCraftStateNotSyncedWithRedux = useCraftStringifiedState(query);
  const { header: h, footer: f } = useStringifiedCommonElements(query);
  let header = h;
  let footer = f;

  const selectedPage = builderPages.find(({ id }) => id === selectedPageId);

  if (isEditingColorTheme && oldTheme.id !== newTheme.id) {
    currentCraftStateNotSyncedWithRedux = selectedPage?.craftState;

    const extractedCommonElements = extractCommonElementsFromState(
      currentCraftStateNotSyncedWithRedux
    );

    header = extractedCommonElements.header;
    footer = extractedCommonElements.footer;
  }

  let calendarSettings: CalendarSettings | undefined;

  const builderPagesTemp = builderPages.map(page => {
    let thisPage = appendCommonElementsAndEncodePage({
      header,
      footer,
      page,
      selectedPageId,
      currentCraftStateNotSyncedWithRedux
    });

    (thisPage as BuilderPageDataType) = removeEmptyContainersFromPage(
      thisPage
    ) as BuilderPageDataType;
    calendarSettings = !calendarSettings ? extractCalendarSettings(thisPage) : calendarSettings;

    return thisPage;
  });

  return {
    builderPages: builderPagesTemp,
    calendarSettings,
    header,
    footer,
    funnelTheme: shouldApplyFunnelTheme ? newTheme : oldTheme
  };
};

export const getEncodedAgencyBuilderPages = ({
  agencyOSBuilderPages,
  selectedAgencyBuilderPageId,
  query
}: GetEncodedAgencyDashboardBuilderPages) => {
  query = (query || window.craftJsAgencyDashboardPageBuilderQuery) as Delete<
    useInternalEditorReturnType<any>['query'],
    'deserialize'
  >;
  const currentCraftStateNotSyncedWithRedux = useCraftStringifiedState(query);
  const agencyOSBuilderPagesTemp = agencyOSBuilderPages.map(page => {
    let thisPage = page;
    page.craftState =
      thisPage.id === selectedAgencyBuilderPageId
        ? currentCraftStateNotSyncedWithRedux
        : thisPage.craftState;
    (thisPage as AgencyOSBuilderPageDataType) = removeEmptyContainersFromPage(
      thisPage
    ) as AgencyOSBuilderPageDataType;
    return thisPage;
  });

  return {
    agencyOSBuilderPages: agencyOSBuilderPagesTemp
  };
};

export const getThemeToApplyInFunnel = ({
  oldTheme,
  newTheme
}: {
  oldTheme: FunnelThemeType;
  newTheme: FunnelThemeType;
}) => {
  if (newTheme.id === oldTheme.id) {
    return newTheme;
  } else return oldTheme;
};

export const getOtherEmailAddressesToSendForInternalConfirmation = ({
  emailTemplates
}: {
  emailTemplates: EmailTemplateType[];
}) =>
  emailTemplates.find(({ type }) => type === EmailType.INTERNAL_CONFIRMATION)
    ?.otherEmailAddressesToSend;

export const getAllContainersSectionIds = (
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>,
  currentNodeId: string | null = null
) => {
  const serializedNodes = query?.getSerializedNodes();
  return Object.keys(serializedNodes)
    .filter(
      key =>
        [CRAFT_ELEMENTS.CONTAINER, CRAFT_ELEMENTS.ELEMENT_APPENDER].includes(
          serializedNodes[key].displayName
        ) &&
        (!currentNodeId || key !== currentNodeId)
    )
    .map(container => serializedNodes[container].props.sectionId)
    .filter(Boolean);
};

export const checkIfProvidedElementsExistsInBuilder = (query: any, nodesToCheck: string[]) => {
  const keys: any = {};
  nodesToCheck.map(key => {
    keys[key] = false;
  });

  const nodes: Nodes = query.getState()?.nodes;

  Object.keys(nodes).forEach(key => {
    const nodeName = nodes[key].data.displayName;
    if (nodesToCheck.includes(nodeName)) {
      keys[nodeName] = true;
    }
  });

  return keys;
};

export const isProvidedElementExistInFunnel = (
  element: string[],
  notInvolveCurrentPage?: boolean
) => {
  const pages = useAllPages();
  const selectedPage = useBuilderSelectedPageId();

  let tempPages = notInvolveCurrentPage ? pages.filter(({ id }) => id !== selectedPage) : pages;

  const pagesKeys: any = {};

  element.forEach(element => {
    pagesKeys[element] = false;
  });

  tempPages.forEach(page => {
    const craftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));
    return Object.keys(craftState).forEach(key => {
      const nodeName = craftState[key].displayName;
      if (element.includes(nodeName)) {
        if (page.type == contactPageType && nodeName == CRAFT_ELEMENTS.CONTACT_FORM) return;
        pagesKeys[nodeName] = true;
        return true;
      }
    });
  });

  return pagesKeys;
};

export const handleBringSlideToFront = async (carouselId: string, slideID: string) => {
  await sleep(0);
  document.querySelector(`#Carousel-${carouselId} > .carousel-inner`)?.childNodes.forEach(el => {
    // @ts-ignore
    el?.classList.remove('active');
  });
  document.querySelector(`#slide-${slideID}`)?.classList.toggle('active');
};

export const getAllChildNodes = (nodes: Nodes, parentId: string, childNodes: Nodes = {}): Nodes => {
  const children = nodes[parentId]?.data?.nodes;
  childNodes[parentId] = nodes[parentId];
  const currentChildNodes = children?.reduce((prev, curr) => {
    const node = nodes[curr];

    prev[curr] = node;
    if (node.data.nodes.length) {
      return { ...prev, ...getAllChildNodes(nodes, curr, childNodes) };
    } else {
      return { ...prev };
    }
  }, {} as Nodes);
  return { ...currentChildNodes, ...childNodes };
};

export const checkIfUserIntractingComponentExisitInTree = (
  nodes: Nodes,
  parentId: string,
  childNodes: Nodes = {}
): boolean => {
  const currentNodeName = nodes[parentId]?.data.displayName;
  if (userInteractingComponents.includes(currentNodeName)) {
    return true;
  }
  const children = nodes[parentId]?.data?.nodes || [];
  childNodes[parentId] = nodes[parentId];
  let userComponentExisit = false;
  for (let index = 0; index < children.length; index++) {
    const curr = children[index];
    const node = nodes[curr];
    if (userInteractingComponents.includes(node.data.displayName)) {
      userComponentExisit = true;
      break;
    }
    if (node.data.nodes.length) {
      const exisit = checkIfUserIntractingComponentExisitInTree(nodes, curr);
      if (exisit) {
        userComponentExisit = exisit;
        break;
      }
      return exisit;
    }
  }
  return userComponentExisit;
};

type ThemeType = FunnelThemeType | AgencyOSThemeType;

type ThemeWithColors = ThemeType & {
  [key: string]: any;
};

export const getElementColor = (clr: string | undefined, theme: ThemeWithColors) => {
  if (clr) {
    return theme[clr] || clr;
  }

  return clr;
};

export const handleSelectCraftElement = ({
  openParentSettingsOnChildSettings,
  openChildSettingsOnParentSettings,
  nodeId,
  query,
  actions
}: {
  openParentSettingsOnChildSettings?: boolean;
  openChildSettingsOnParentSettings?: boolean;
  nodeId: string;
  query: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
  actions: any;
}) => {
  if (openParentSettingsOnChildSettings) {
    parentSelectionOnChildClick(nodeId, query, actions.selectNode);
  } else if (openChildSettingsOnParentSettings) {
    childSelectionOnParentClick(nodeId, query, actions.selectNode);
  } else {
    actions.selectNode(nodeId);
  }
};

export const getParentNodeId: any = (currentNodeId: any, query: any, parentNodeName: string) => {
  const allNodes = query.getSerializedNodes();
  const currentNode = allNodes[currentNodeId];
  if (currentNode.displayName === parentNodeName) {
    return currentNodeId;
  } else {
    return getParentNodeId(currentNode.parent, query, parentNodeName);
  }
};

export const getSiblingNodeNames = (query: any, parentId: string, nodeId: string) => {
  const serializedNodes = query.getSerializedNodes();
  const parentDisplayName = serializedNodes?.[parentId]?.displayName;
  const containerNodeId = getParentNodeId(
    nodeId,
    query,
    parentDisplayName || CRAFT_ELEMENTS.ELEMENT_APPENDER
  );
  const allSiblings = query
    .node(containerNodeId)
    .descendants(true)
    ?.map((ele: any) => serializedNodes?.[ele]);
  return allSiblings;
};
