import { BookingTag } from '../container/Events';

export const getTagsFromHashMap = (tags: BookingTag[], tagsHashMap: any) => {
  return tags
    .map((tag: BookingTag) => {
      if (tag.id) {
        return tagsHashMap[tag.id];
      }
    })
    .filter((tag: BookingTag) => tag !== undefined);
};

export const colors = [
  { label: 'grau', color: 'gray' },
  { label: 'green', color: 'green' },
  { label: 'volcano', color: 'volcano' },
  { label: 'orange', color: 'orange' },
  { label: 'gold', color: 'gold' },
  { label: 'lime', color: 'lime' },
  { label: 'cyan', color: 'cyan' },
  { label: 'blue', color: 'blue' },
  { label: 'geekblue', color: 'geekblue' },
  { label: 'purple', color: 'purple' }
];

export const getRandomColorForTags = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex].color;
};
