import React, { useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import download from 'downloadjs';
import JSZip from 'jszip';
import { useEditor } from '@craftjs/core';
import { CRAFT_ELEMENTS } from '../../AdBuilder/config/craftElements';
import {
  getAdContent,
  sleep,
  splitTemplateIntoFiles,
  useCraftSerializedState
} from '../../helper/craftJs';
import mixpanel from 'mixpanel-browser';
import { getFunnelIdFromToken, mixpanelEvents } from '../../../UI/utils/mixpanel';
import { useAppDispatch } from '../../../redux/hooks';
import { saveAdBuilderStateThunk } from '../../AdBuilder/redux/thunk';

export const useDownloadAd = () => {
  const { actions, query } = useEditor();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const handleDownloadAd = async (elemAppender?: string[]) => {
    await dispatch(saveAdBuilderStateThunk());
    try {
      actions.selectNode('');
      await sleep(0);
      setLoading(true);
      const state = useCraftSerializedState(query);
      let ElementAppender: string[] = [];
      if (elemAppender?.length) {
        ElementAppender = elemAppender;
      } else {
        ElementAppender = Object.keys(state).filter(
          key => state[key].displayName === CRAFT_ELEMENTS.ELEMENT_APPENDER
        );
      }

      const adContent = await Promise.all(
        ElementAppender.map(single => getAdContent(single, state, query))
      );

      const adContentFile = adContent.map((single, i) =>
        splitTemplateIntoFiles(single, `ad-${i + 1}`)
      );

      const finalData = adContentFile.reduce((prev, curr) => [...prev, ...curr], []);

      const zip = new JSZip();
      finalData.forEach(file => {
        zip.file(file.name, file.data);
      });

      const zipFile = await zip.generateAsync({ type: 'blob' });
      download(zipFile, `ad-builder_${new Date().toLocaleDateString()}.zip`);

      mixpanel.track(mixpanelEvents.AD_DOWNLOAD, {
        funnelId: getFunnelIdFromToken()
      });
    } catch (error) {}
    setLoading(false);
  };

  return { handleDownloadAd, loading };
};

const DownloadAd = () => {
  const { handleDownloadAd, loading } = useDownloadAd();

  return (
    <Button
      type="primary"
      onClick={() => handleDownloadAd()}
      shape="round"
      icon={<CloudDownloadOutlined />}
      loading={loading}
      className="block-on-funnel-invalidation"
    >
      Herunterladen
    </Button>
  );
};

export default DownloadAd;
