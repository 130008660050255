import { FunnelInterface } from '../../Funnel/components/GeneralSettingsBuilder';
import dateFormats from '../../config/dateFormats';
import { AdaptedBookingChoiceInterface } from '../components/Event/Event';
import {
  BookingChoiceInterface,
  BookingInterface,
  LeadQualifierChoiceInterface
} from '../container/Events';
import { Parser } from 'json2csv';
import moment from 'moment';

const columns = [
  // { title: 'Funnel-Titel ', selector: 'funnelName' },
  { title: 'Name', selector: 'name' },
  { title: 'Telefon', selector: 'phone' },
  { title: 'E-Mail', selector: 'email' },
  { title: 'Zusatzfeld', selector: 'generalPurpose' },
  { title: 'Terminstart ', selector: 'eventStart' },
  { title: 'Terminende ', selector: 'eventEnd' },
  { title: 'Status ', selector: 'status' },
  { title: 'Dateien ', selector: 'file' }
];

export const csvMaker = ({
  bookings,
  leadQualifiers,
  funnels
}: {
  bookings: BookingInterface[];
  leadQualifiers: LeadQualifierChoiceInterface[];
  funnels: FunnelInterface[];
}) => {
  const data: any = [];

  bookings.map(single => {
    const tempTable: any = {};
    const funnelTitleById = funnels.reduce((acc: any, { title, id }: any) => {
      acc[id] = title;
      return acc;
    }, {});

    tempTable['Funnel-Titel'] = funnelTitleById[single.funnelId as number];

    columns.map(_ => {
      if (_.selector === 'status') {
        tempTable[_.title] = single.bookingStatus?.status;
        return;
      } else if (_.selector === 'file') {
        if (single.file.length === 1) {
          tempTable[_.title] = single.file[0];
        } else if (single.file.length === 0) {
          tempTable[_.title] = '';
        }
        tempTable[_.title] = single.file.map(_ => _.fileLink).join(', ');
        return;
      } else if (_.selector === 'eventEnd') {
        tempTable[_.title] = single[_.selector]
          ? moment(single[_.selector]).format(dateFormats.dateTime)
          : null;
        return;
      } else if (_.selector === 'eventStart') {
        tempTable[_.title] = single[_.selector]
          ? moment(single[_.selector]).format(dateFormats.dateTime)
          : null;
        return;
      }
      //  @ts-ignore
      tempTable[_.title] = single[_.selector];
    });

    const choice = getFormattedChoices(single.choices, leadQualifiers);
    Object.keys(choice).map(key => {
      // Joining the Answers
      tempTable[key] =
        choice[key].title.length !== 0
          ? choice[key].title.join(', ')
          : choice[key].value.join(', ');
    });
    data.push(tempTable);
  });
  const parser = new Parser();
  return parser.parse(data);
};

const getFormattedChoices = (
  choices: BookingChoiceInterface[],
  leadQualifier: LeadQualifierChoiceInterface[]
) => {
  const choiceCollector: any = {};
  choices.forEach((ch: AdaptedBookingChoiceInterface) => {
    let leadQualifierObj = leadQualifier.find(lq => ch.leadQualifierId === lq.id);
    if (leadQualifierObj) {
      // @ts-ignore
      let choiceObj: LeadQualifierChoiceInterface | undefined = leadQualifierObj.choices.find(
        (c: LeadQualifierChoiceInterface) => ch.choiceId === c.id
      );
      if (choiceObj) {
        // @ts-ignore
        const question = `${leadQualifierObj.question}`;

        if (!choiceCollector[question]) choiceCollector[question] = { title: [], value: [] };

        if (ch.stringValue || ch.numberValue) {
          choiceCollector[question].value.push(
            `${ch.stringValue || ch.numberValue} ${choiceObj.unit || ''}`
          );
        } else {
          choiceCollector[question].title.push(`${choiceObj.title}`);
        }

        if (ch.voiceMessageAWSKey)
          choiceCollector[question].voiceMessageAWSKey = ch.voiceMessageAWSKey;

        if (ch.totalDurationInSeconds) choiceCollector[question].time = ch.totalDurationInSeconds;
      }
    }
  });
  return choiceCollector;
};
