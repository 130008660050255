import {
  Content,
  EditorOptions,
  useEditor as useTiptapEditor,
  Editor as TiptapEditor
} from '@tiptap/react';
import StarterKitExtension from '@tiptap/starter-kit';
import TextAlignExtension from '@tiptap/extension-text-align';
import PlaceholderExtension from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Color } from '@tiptap/extension-color';
import LinkExtension from '@tiptap/extension-link';
import ListKeymap from '@tiptap/extension-list-keymap';
import { CustomEnterBehavior, editorClasses, listItemColor } from './helpers/EditorHelpers';
import { FontSize } from '../../Builder/components/elements/RichTextComponent/utils';

export type UseEditorProps = Partial<EditorOptions> & {
  content?: string | Content;
  placeholder?: string;
  editable?: boolean;
  onChange?: (editor: TiptapEditor) => void;
  history?: boolean;
  disableBullets?: boolean;
  forceWhiteColorOnPaste?: boolean;
};

export const useCustomEditor = ({
  content = '',
  placeholder = 'Write something awesome...',
  editable = true,
  onChange,
  history = true,
  disableBullets = false,
  forceWhiteColorOnPaste = false,
  ...other
}: UseEditorProps): TiptapEditor | null =>
  useTiptapEditor({
    content,
    editable,
    extensions: [
      ...(disableBullets ? [] : [
        listItemColor.configure({
          HTMLAttributes: { class: editorClasses.content.listItem }
        })
      ]),
      StarterKitExtension.configure({
        history: history as false,
        codeBlock: false,
        heading: { HTMLAttributes: { class: editorClasses.content.heading }, levels: [1, 2, 3] },
        horizontalRule: { HTMLAttributes: { class: editorClasses.content.hr } },
        listItem: false,
        blockquote: { HTMLAttributes: { class: editorClasses.content.blockquote } },
        bulletList: disableBullets ? false : { HTMLAttributes: { class: editorClasses.content.bulletList } },
        orderedList: disableBullets ? false : { HTMLAttributes: { class: editorClasses.content.orderedList } },
      }),
      ...(disableBullets ? [] : [
        ListKeymap
      ]),
      TextAlignExtension.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right', 'justify'],
        defaultAlignment: 'left'
      }),
      PlaceholderExtension.configure({
        placeholder,
        emptyEditorClass: editorClasses.content.placeholder
      }),
      TextStyle.configure({
        HTMLAttributes: {
          class: 'text-style'
        }
      }),
      CustomEnterBehavior,
      Color,
      Underline.configure({ HTMLAttributes: { class: editorClasses.content.underline } }),
      LinkExtension.configure({
        autolink: true,
        openOnClick: false,
        HTMLAttributes: { class: editorClasses.content.link }
      }),
      FontSize.configure({
        types: ['textStyle'],
        defaultSize: '1em'
      })
    ],
    onUpdate({ editor: _editor }) {
      onChange?.(_editor);
    },
    editorProps: {
      handlePaste: (view, event) => {
        event.preventDefault();
        const text = event.clipboardData?.getData('text/plain');
        const { state } = view;
        if (text && state.selection.empty) {
          const { from } = state.selection;
          const currentMarks = state.storedMarks || state.selection.$from.marks();
          const tr = state.tr.insertText(text, from);
          const to = from + text.length;
          if (forceWhiteColorOnPaste) {
            const nonColorMarks = currentMarks.filter(mark => mark.type.name !== 'textStyle');
            tr.addMark(from, to, state.schema.marks.textStyle.create({ color: '#fff' }));
            nonColorMarks.forEach(mark => {
              tr.addMark(from, to, mark);
            });
          } else if (currentMarks.length > 0) {
            currentMarks.forEach(mark => {
              tr.addMark(from, to, mark);
            });
          }

          view.dispatch(tr);
          return true;
        }
        if (text) {
          const { from, to } = state.selection;
          const currentMarks = state.storedMarks || state.selection.$from.marks();
          const textNode = state.schema.text(text, currentMarks);
          const tr = state.tr.replaceWith(from, to, textNode);
          view.dispatch(tr);
          return true;
        }
        return false;
      }
    },
    ...other
  });
