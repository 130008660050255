import { useEditor } from '@craftjs/core';
import { isElementExistInFunnel } from '../helper/elementTemplate';

export const useCheckElementExistence = () => {
  const { checkElementExistInFunnel } = useEditor((_, query) => {
    return { checkElementExistInFunnel: (key: string) => isElementExistInFunnel(query, key, true) };
  });

  return {
    checkElementExistInFunnel,
    checkElementExists: (key: string) => checkElementExistInFunnel(key) as boolean
  };
};
