export const faqData = [
  {
    title: 'Ist MEETOVO DSGVO konform?',
    content: `Ja, MEETOVO ist DSGVO konform. Sicherheit und Datenschutz haben bei uns höchste Priorität. Unsere Infrastruktur und Daten werden auf Hochsicherheitsservern in Frankfurt gespeichert. In Zusammenarbeit mit einer Hamburger Rechtskanzlei haben wir alle Funktionen datenschutzkonform gestaltet. Dein Cookie-Banner ist in deine Funnels integriert. Zudem erhältst du einen Datenschutzbaustein für deine Datenschutzerklärung, um deine Funnels DSGVO-konform anzubieten. Einen ADV-Vertrag und die TOMs kannst du im Support-Chat zur Unterzeichnung anfordern.`
  },
  {
    title: 'Funktioniert Social Recruiting auch in meiner Branche?',
    content: `Ja, Recruiting-Funnels können in nahezu jeder Branche eingesetzt werden. Nutze die Reichweite von Millionen Menschen auf Social Media, statt dich nur auf die begrenzte Reichweite von aktiven Quellen wie Jobportalen zu verlassen. Der Facebook-Konzern mit Facebook, Instagram und WhatsApp allein hat über 3 Milliarden Nutzer – jeder kann erreicht werden, darunter sind auch deine zukünftigen Mitarbeiter. In MEETOVO kannst du alles kinderleicht selbst zusammenklicken und Bewerbungen erhalten. Besonders vorteilhaft ist die Möglichkeit, mit wenigen Klicks Facebook und Instagram zu bespielen, um wechselbereite Kandidaten zu erreichen, die über herkömmliche Wege niemals erreichbar wären. Dieser Vorgang hat sich bereits hunderttausendfach bewährt. Je nach Qualifizierungsgrad könnten die Preise pro Bewerbung höher sein.`
  },
  {
    title: 'Ist die Testphase wirklich kostenfrei?',
    content:
      'Ja, die Testphase ist vollkommen kostenfrei und es entsteht kein automatisches Abonnement. Es werden keine Bezahldaten vorab abgefragt. Nach Ablauf der Testzeit passiert nichts automatisch. Du kannst selbst entscheiden, ob du eines unserer Pakete wählen möchtest. Du kannst die Software sofort umfassend testen. Einfach registrieren und in 30 Sekunden bist du im Dashboard – ganz ohne mit uns sprechen zu müssen. Selbst wenn du dich nach den 14 Tagen für eine kostenpflichtige Version pro-aktiv entscheidest, kannst du mit einem Klick wieder kündigen, ohne Fristen. Es stehen dir monatliche oder jährliche Laufzeiten zur Auswahl. Wir zwingend niemanden etwas auf.'
  },
  {
    title: 'Mit welchen Kosten kann ich pro Bewerbung rechnen?',
    content: `Tendenziell weniger als über andere Recruiting-Wege. Auf unserer Preise-Seite findest du transparent alle Preise. Die Kosten sind leicht kalkulierbar. Du hast zwei Investitions-Punkte: Die Software-Kosten (also MEETOVO, weitere Tools sind optional) und das Werbebudget auf Social Media. Häufig empfiehlt sich ein Tagesbudget von mindestens 30 €, um genug Impressionen zu erzielen. In der Regel entstehen nach einem Monat ausreichend Bewerbungen, um eine Position zu besetzen. Unsere Kunden investieren üblicherweise zwischen 10 € und 150 € pro Bewerbung – je nach Branche, Region und Marketing-Attraktivität. Dafür gibt es ausführliche Erklärungen, sodass du es kinderleicht selbst umsetzen kannst.`
  },
  {
    title: 'Ich bin Laie. Kann jeder mit MEETOVO rekrutieren?',
    content: `Ja, jeder kann Social Recruiting schnell erlernen. In unserer kostenfreien Recruiting Academy innerhalb der Software lernst du alles von den Grundlagen bis zum Profi-Level (7 Stunden Videomaterial). Zusätzlich unterstützt dich dein Recruiting-Experte über den Support-Chat unten rechts. Für autodidaktisches Lernen steht dir unser Hilfecenter zur Verfügung.`
  }
];
