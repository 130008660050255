import React, { useEffect } from 'react';
import { Element, useEditor } from '@craftjs/core';
import ImageComponent from '../elements/ImageComponent';
import ElementAppender from '../elements/common/ElementAppender/container/ElementAppender';
import { getUniqueId } from '../../../helper/craftJs';
import PlainTextComponent from '../elements/PlainTextComponent/container/PlainTextComponent';
import { elementTypeObj, mediaTypeEnum, textTypesEnum } from '../../interfaces/TextTypeInterface';
import headlineTextData from '../../data/headlineTextData';
import MediaContentComponent from '../elements/MediaContentWrapper/container/MediaContentWrapperComponent';
import Footer from '../elements/Footer';
import RichTextComponent from '../elements/RichTextComponent';
import {
  BuilderPageDataType,
  FUNNEL_THEME_KEYS,
  FunnelThemeType
} from '../../../interfaces/builderSliceTypes';
import LogoComponent from '../elements/LogoComponent';
import ImageColorizedBanner from '../elements/ImageColorizedBanner';
import { replaceHtmlTextColor } from '../../../components/elements/RichTextComponent/helpers/textManipulators';
import { AD_TYPES } from '../../Templates/container/TemplateTypesView';
import { getLogoUrl } from '../../utils/adBuilderUtils';

export interface AiGeneratedAdPrompts {
  message: string;
  type: string;
}

enum AdElementsTypes {
  Teaser = 'Teaser',
  MainText = 'MainText',
  Cta = 'Cta',
  MediaText = 'MediaText',
  Headline = 'Headline',
  BannerText = 'BannerText'
}

export const adTemplatePreview = (
  builderPages: BuilderPageDataType[],
  theme: FunnelThemeType,
  nodeId: string,
  aiGeneratedData: AiGeneratedAdPrompts[],
  adImage: string,
  adType: string,
  adCraftState?: any
) => {
  const funnelLogo = getLogoUrl(builderPages, adCraftState);

  const convertToHashmap = (data: any) => {
    return data.reduce((acc: any, { type, message }: any) => {
      acc[type] = message;
      return acc;
    }, {});
  };
  const messageHashmap = convertToHashmap(aiGeneratedData);
  return (
    <Element
      id={nodeId}
      is={ElementAppender}
      elementTitle={'ImageComponent'}
      backgroundColor={'#F3F3F3'}
      paddingTopClass="container__padding-s-top"
      isAiAd={true}
      hasAdGenerationInput={true}
      aiAdType={adType}
    >
      [
      <Element
        id={getUniqueId()}
        is={PlainTextComponent}
        elementType={textTypesEnum.TEASER}
        templateId={headlineTextData.data[0].content[0].id}
        html={messageHashmap[AdElementsTypes.Teaser]}
        onlySettingsReq={true}
        isEdited={false}
      />
      ,
      <Element
        id={getUniqueId()}
        is={PlainTextComponent}
        elementType={textTypesEnum.MAIN_TEXT}
        templateId={headlineTextData.data[0].content[0].id}
        html={messageHashmap[AdElementsTypes.MainText]}
        onlySettingsReq={true}
        isEdited={false}
      />
      ,
      <Element
        id={getUniqueId()}
        is={PlainTextComponent}
        elementType={textTypesEnum.CTA}
        templateId={headlineTextData.data[0].content[0].id}
        html={messageHashmap[AdElementsTypes.Cta]}
        onlySettingsReq={true}
        isEdited={false}
      />
      ,
      <Element id={getUniqueId()} is={MediaContentComponent}>
        <Element
          id={getUniqueId()}
          is={ImageComponent}
          src={adType === AD_TYPES.EMPLOYEE_TESTIMONIAL ? "/dashboard/ad-builder/emplyee-placeholder.jpg" : adImage}
          logoUrl={funnelLogo}
          backgroundColor={FUNNEL_THEME_KEYS.ACCENT_COLOR}
          opacity={0.2}
        >
          <Element
            id={getUniqueId()}
            is={LogoComponent}
            canHide={{ hideSwich: false }}
            url={funnelLogo || '/dashboard/funnel-builder/default-logo.png'}
            elementType={elementTypeObj[mediaTypeEnum.LOGO]}
          />
          <Element
            id={getUniqueId()}
            is={RichTextComponent}
            canHide={{ hideSwich: false }}
            elementType={elementTypeObj[mediaTypeEnum.HAUPTINHALT]}
            className={adType === AD_TYPES.EMPLOYEE_TESTIMONIAL ? 'ad-type__quote' : ''}
            html={replaceHtmlTextColor(messageHashmap[AdElementsTypes.MediaText], '#ffffff', true)}
            onlySettingsReq={true}
          />

          <Element
            id={getUniqueId()}
            is={ImageColorizedBanner}
            backgroundColor={FUNNEL_THEME_KEYS.ACCENT_COLOR}
            onlySettingsReq={true}
            canHide={{ hideSwich: false }}
          >
            <Element
              id={getUniqueId()}
              is={RichTextComponent}
              textType={mediaTypeEnum.BANNER_TEXT}
              onlySettingsReq={true}
              elementType={elementTypeObj[mediaTypeEnum.CALL_TO_ACTION]}
              canHide={{ hideSwich: false }}
              html={replaceHtmlTextColor(messageHashmap[AdElementsTypes.BannerText], '#ffffff', true)}
            ></Element>
          </Element>
        </Element>
      </Element>
      ,
      <Element id={getUniqueId()} is={Footer}>
        <Element
          id={getUniqueId()}
          is={PlainTextComponent}
          elementType={textTypesEnum.HEADLINE}
          templateId={headlineTextData.data[0].content[0].id}
          html={messageHashmap[AdElementsTypes.Headline]}
          onlySettingsReq={true}
          isEdited={false}
        />
      </Element>
      ]
    </Element>
  );
};

// KEEPING THIS FOR REFERENCE

// aiGeneratedData = [
//   { message: 'Gesucht: examinierte Pflegefachkräfte (m/w/d) mit Herz ❤️', type: 'Teaser' },
//   {
//     message: `Mehr als nur einen Job als Pflegekraft (m/w/d) gewünscht? Werde Teil einer zweiten Familie: [der Muster GmbH]
// Wir bieten dir:
// ➡️ [Brennendes Bedürfnis: z.B. Finanziell sorglos: Übertarifliche Vergütung. Betriebliche Krankenversicherung. Zusatzleistungen und 13. Gehalt.
// ➡️ [Brennendes Bedürfnis: z.B. Zusammenhalt wie in einer Familie: freundliche, greifbare und strukturierte Vorgesetzte. Wir halten unser Wort!]
// ➡️ [Brennendes Bedürfnis: z.B. Wertschätzung, die du verdienst: Du bist unersetzlich, das wissen wir mit regelmäßigen Teamevents zu belohnen!]
// ... und noch so viele weitere Gründe, ein Teil unserer Familie zu werden. In [Voll- und Teilzeit].`,
//     type: 'MainText'
//   },
//   {
//     message: `… dann bewirb dich jetzt bei uns als Pflegekraft (m/w/d). Klicke dafür unten einfach nur auf Button.
// Dauert nicht länger als zwei Minuten. Kein Lebenslauf nötig. Versprochen! 🤝`,
//     type: 'Cta'
//   },
//   {
//     message: `📍 [Stadt]
//       Endlich genug Zeit für die Bewohner haben
//       Wir suchen Pflegekräfte (m/w/d)`,
//     type: 'MediaText'
//   },
//   { message: '✨ Traumjob in 2 Minuten sichern ', type: 'Headline' },
//   { message: ' ➡ Expressbewerbung. Dauer: 2 MinutenLLLLLLl ⬅', type: 'BannerText' }
// ];
