import { Button, Modal, Result } from 'antd';
import React from 'react';

const WarningModalForDisablingPage = ({
  onCancel,
  visible,
  onSubmit,
  title,
  content,
  cancelButtonText,
  submitButtonText
}: {
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  content: string;
  cancelButtonText?: string;
  submitButtonText?: string;
}): JSX.Element => {
  return (
    <Modal visible={visible} onOk={onSubmit} onCancel={onCancel} footer={null}>
      <Result
        icon={
          <img
            width={400}
            style={{ borderRadius: '15px' }}
            src={'/dashboard/funnel-builder/disabled-contact-field.jpg'}
            alt="disabled contact field"
          />
        }
        status="info"
        title={title}
        subTitle={content}
        extra={[
          <Button type="primary" key="WarningForWithoutContainerElement__1" onClick={onSubmit}>
            {submitButtonText || 'Ja, diese Seite verwenden'}
          </Button>,
          <Button key="WarningForWithoutContainerElement__2" type="link" onClick={onCancel}>
            {cancelButtonText || 'Nein, Dateneingabeseite verwenden'}
          </Button>
        ]}
      />
    </Modal>
  );
};

export default WarningModalForDisablingPage;
