import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import AddNewTile from '../../SingleChoiceComponent/components/AddNewTile';
import { defaultFAQItems, FAQSingleTileElement } from './CompleteFaqComponent';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import { ActionsController } from '../../../sharedUI/ActionsController';

export interface Props {
  children?: any;
  className?: string;
  onlySettingsReq?: any;
}

export default function FAQContainerHOC({ children, className, onlySettingsReq, ...props }: Props) {
  const { actions, query } = useEditor();
  const isMobileView = useIsMobileView();
  const {
    currentNode,
  } = useNode(node => {
    return {
      currentNode: node
    };
  });
  const data = defaultFAQItems[0];
  const appendFAQNode = () => {
    const firstFAQChildrenNodeId = children.props.children[0].props.id
    const firstFAQChildrenProps = query.getSerializedNodes()[firstFAQChildrenNodeId].props
    const canvasNodeTree = query
      .parseReactElement(FAQSingleTileElement(data , firstFAQChildrenProps))
      .toNodeTree();

    actions.addNodeTree(canvasNodeTree, currentNode.id);
    actions.selectNode(canvasNodeTree.rootNodeId);
  };

  return (
    <ActionsController
      {...props}
      className={`${className ?? ''} choice-container-hoc ${
        isMobileView ? 'mobile-choice-view' : ''
        }`}
    >
      {children}
      <AddNewTile handleAddClick={() => appendFAQNode()} colSize={{ md: 12 }} className="px-0" />
    </ActionsController>
  );
}

const FAQContainerHOCSettings = {
  customRules: { noSettings: true }
}

FAQContainerHOC.craft = {
  name: CRAFT_ELEMENTS.FAQContainerHOC,
  props: FAQContainerHOCSettings,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.FAQ_TILE;
    },
    canMoveOut: () => false
  }
};
