import React, { useEffect } from 'react';
import { Editor as TipTapEditor } from '@tiptap/react';
import { Input, Typography, Alert, Table, message } from 'antd';
import {
  EmailTemplateType,
  EmailType,
  MessagingAndEmailTempaltes,
  MessagingTemplateTabType
} from '../../interfaces/builderSliceTypes';
import { columns, explanations } from './utils';
import { BUILDER_VALIDATION_ERRORS } from '../../container/BuilderValidator';
import { translateEmailToolbarText } from '../../helper/sharedFunctions';
import NewApplicationNotification from './NewApplicationNotification';
import {
  addBuilderValidationError,
  editFunnelSavedIndicator,
  removeBuilderValidationError,
  updateEmailTemplateByType,
  useChannelTemplatesData,
  useIsFunnelSaved
} from '../../redux/builderSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { filteredTokensAsPerEmailType } from '../../helper/filterTokens';
import { maxRichTextLength } from '../../../constants';
import useCustomDebounce from '../../../hooks/useCustomDebounce';
import ScheduledNotificationTemplateSelectList from './ScheduledNotificationTemplateSelectList';
import { useCustomEditor } from '../../../SharedUI/components/useEditor';
import TextEditor from '../../../SharedUI/components/TextEditor';

function EmailTemplateForm({
  onChange,
  emailTemplate,
  emailTemplateEnabled
}: {
  onChange: (e: EmailTemplateType | MessagingTemplateTabType) => void;
  emailTemplate: EmailTemplateType;
  emailTemplateEnabled: boolean;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const { templates } = useChannelTemplatesData();
  const isSaved = useIsFunnelSaved();
  const { emailTemplate: internalConfirmationEmailTemplate } =
    (templates.find(
      ({ emailTemplate: _emailTemplate }) => _emailTemplate.type === EmailType.INTERNAL_CONFIRMATION
    ) as MessagingAndEmailTempaltes) || {};

  const filteredTokens = filteredTokensAsPerEmailType(emailTemplate.type);

  useEffect(() => {
    translateEmailToolbarText();
  }, []);

  const handleUpdateOtherEmailAddressesToSend = (_emailsAddresses: string): void => {
    dispatch(
      updateEmailTemplateByType({
        type: EmailType.INTERNAL_CONFIRMATION,
        template: {
          ...internalConfirmationEmailTemplate,
          otherEmailAddressesToSend: _emailsAddresses
        }
      })
    );
    if (isSaved) {
      dispatch(editFunnelSavedIndicator(false));
    }
  };

  const debouncedMaxTextLengthCheck = useCustomDebounce((_editor: TipTapEditor) => {
    const newText = _editor.getText({
      blockSeparator: '\u0001'
    });

    if (newText.length > maxRichTextLength) {
      message.warning(`Maximale Wortlänge ist ${maxRichTextLength}`);
    }
  }, 200);

  const handleEditorStateChanged = (_editor: TipTapEditor): void => {
    onChange({ ...emailTemplate, html: _editor.getHTML() });
    debouncedMaxTextLengthCheck(_editor);
    if (_editor.getText()) {
      dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_EDITOR));
    } else {
      dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_EDITOR));
    }
  };

  const tiptapEditor = useCustomEditor({
    content: emailTemplate.html,
    parseOptions: {
      preserveWhitespace: 'full'
    },
    onChange: handleEditorStateChanged
  });

  return (
    <div
      className={`template-form disable-layer ${!emailTemplateEnabled &&
        'disable-layer__disabled'}`}
    >
      <Alert
        message={explanations[emailTemplate.type]}
        className="remove-margin-top"
        type="info"
        showIcon
      />
      {(emailTemplate.type === EmailType.SCHEDULED ||
        emailTemplate.type === EmailType.REMINDER) && (
        <ScheduledNotificationTemplateSelectList
          scheduledTime={emailTemplate?.scheduledTime}
          timeInMinutesTillEvent={emailTemplate?.timeInMinutesTillEvent}
          onChange={onChange}
          templateType={emailTemplate.type}
          data={emailTemplate}
        />
      )}

      <div className="mb-3">
          {emailTemplate.type === EmailType.INTERNAL_CONFIRMATION && (
            <NewApplicationNotification
              otherEmailAddressesToSend={
                internalConfirmationEmailTemplate?.otherEmailAddressesToSend || ''
              }
              onChange={handleUpdateOtherEmailAddressesToSend}
            />
          )}
          <Typography.Text strong>Betreff</Typography.Text>
          <Input
            className="mt-2"
            placeholder="Bestätigung"
            value={emailTemplate.subject}
            onChange={(e): void => {
              onChange({ ...emailTemplate, subject: e.target.value });
              if (e.target.value) {
                dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_SUBJECT));
              } else {
                dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_SUBJECT));
              }
            }}
          />
        </div>
        <TextEditor
          editor={tiptapEditor as TipTapEditor}
          showHeadingBlock={false}
          showStrikeThrough={false}
          showPointsMaker={false}
          showColorPicker={false}
          toolbarClasses="justify-content-start"
          fullItem
          showEmojiPicker
          editorClass='nml__editor__content__root__builder'
          editorRootClassName='nml__editor__root__builder'
        />
      <Table
        className="mt-4"
        pagination={false}
        columns={columns}
        dataSource={filteredTokens}
        size="middle"
      />
    </div>
  );
}

export default EmailTemplateForm;
