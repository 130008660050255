import React, { useEffect, useRef, useState } from 'react';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../redux/userSlice';
import { Role } from '../utils/roleTypes';
import { Button, Drawer } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineNotificationsNone } from "react-icons/md";

const UpvotyChangeLogsDrawer = () => {
  const localUserData = useAppSelector(selectUserData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (open && loading) {
      // INFO: Using requestAnimationFrame to ensure DOM updates are available and to make loading faster
      requestAnimationFrame(() => {
        const upvotyIframeElement = document.querySelector('.upvoty-iframe');
        if (upvotyIframeElement && !upvotyIframeElement.hasChildNodes()) {
          const iframeDiv = document.createElement('div')
          iframeDiv.setAttribute('data-upvoty', '')
          upvotyIframeElement.append(iframeDiv)
          const renderScript = document.createElement('script');
          renderScript.setAttribute('type', 'text/javascript');
          renderScript.innerHTML = `upvoty.init('render', { 'startPage': 'changelog', 'baseUrl': 'meetovo.upvoty.com' });`;
          upvotyIframeElement.append(renderScript)
        }
      })
      setTimeout(() => {
        setLoading(false)
      }, 3000);
    }
  }, [open, loading]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (!localUserData || localUserData?.role === Role.AGENCY_CUSTOMER) return null;

  return (
    <>
      <Button
        shape="circle"
        icon={<MdOutlineNotificationsNone size={28} color='grey'/>}
        size="small"
        onClick={showDrawer}
        className="upvoty-change-logs-drawer-button"
      />
      <Drawer
        placement="right"
        visible={open}
        onClose={onClose}
        bodyStyle={{ padding: 0 }}
        headerStyle={{ border: 0 }}
        width="400px"
        className="upvoty-change-logs-drawer"
        title={
          <div className="upvoty-change-logs-drawer-header">
            <h4>Was gibt es neues?</h4>
            <Button shape="circle" icon={<CloseOutlined />} size="small" onClick={onClose} />
          </div>
        }
      >
        <div className="iframe-wrapper">
          {loading && (
            <div className="loading-overlay">
              <LoadingOutlined spin />
            </div>
          )}
          <div className='upvoty-iframe'></div>
        </div>
      </Drawer>
    </>
  );
};

export default UpvotyChangeLogsDrawer;
