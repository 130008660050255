import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { AiGeneratedTypeProps } from './StatementTemplate';
import { AD_TYPES } from './TemplateTypesView';
import { customStringFieldValidation } from '../../../../UI/utils/formValidation';

const FreeTemplate = ({ formInstance, onSubmit, initialData }: AiGeneratedTypeProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (formInstance) {
      formInstance.current = form;
    }
  }, [formInstance, form]);
  return (
    <Form
      layout="vertical"
      form={form}
      style={{ maxWidth: '500px', margin: '0 auto' }}
      initialValues={{ ...initialData }}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Verwendungszweck (ca. 10 Wörter)"
        name="purpose"
        rules={customStringFieldValidation(3, 500)}
      >
        <Input.TextArea
          placeholder="z.B. Anzeige, die 'Mit' und 'Ohne' uns gegenüberstellt"
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </Form.Item>

      <Form.Item
        label="Anweisung (ca. 1-3 Sätze)"
        name={`promptMessage${AD_TYPES.FREE}`}
        rules={customStringFieldValidation(30, 500)}
      >
        <Input.TextArea
          placeholder="z.B. Stelle unsere USPs gegenüber von durchschnittlichen Unternehmen der Branche"
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </Form.Item>
    </Form>
  );
};

export default FreeTemplate;
